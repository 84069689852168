import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Avatar, Typography } from "@mui/material";
import { onValue, ref } from "firebase/database";
import { getDB } from "../../../firebase";

const AvatarContainer = (props) => {
  const [prevMembers, setPrevMembers] = useState({});
  const [members, setMembers] = useState([]);
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (props.user.game !== "false") {
      const query = ref(getDB(), "/games/" + props.user.game);
      return onValue(query, (snapshot) => {
        const data = snapshot.val();
        if (snapshot.exists()) {
          setMembers(data.players);
        }
      });
    }
  }, [props.user.game]);


  useEffect(() => {
    // Update the previous members state only when new members join
    if (Object.keys(members).length > Object.keys(prevMembers).length) {
      setPrevMembers(members);
    }
  }, [members, prevMembers]);

  const isNewMember = (memberId) => {
    return !prevMembers.hasOwnProperty(memberId);
  };

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      uids: Object.keys(members),
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://getuserimages-nykygdtd7a-uc.a.run.app", requestOptions)
      .then((response) => response.text())
      .then((result) => setImages(JSON.parse(result)))
      .catch((error) => console.log("error", error));
  }, [members]);

  return (
    <div className="memberAvatarContainer">
      {Object.values(members).length > 0 &&
      props.user.game !== "false" ? (
        <div className="memberContainer">
          {Object.entries(members).map(([memberId, member], index) => (
            <motion.div
              key={memberId}
              className="memberItem"
              initial={isNewMember(memberId) ? { opacity: 0, scale: 0.5 } : {}}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                duration: 0.2,
                delay: isNewMember(memberId) ? 0.5 : 0,
                ease: [0, 0.71, 0.2, 1.01],
              }}
            >
              <div style={{width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                {images && images[memberId] !== null ? (
                  <Avatar src={images[memberId]} alt={member.username}>
                    {member?.username.substring(0, 1)}
                  </Avatar>
                ) : (
                  <Avatar alt="profile avatar">
                    {member?.username.substring(0, 1)}
                  </Avatar>
                )}
                <Typography
                  variant="h6"
                  style={{ fontSize: "15px", color: "black" }}
                >
                  {member.username}
                </Typography>
              </div>
            </motion.div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default AvatarContainer;
