import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";
import { ref, onValue } from "firebase/database";
import {
  getDB,
  createGame,
  leaveGame,
  moveGameToPrevious,
  getUserData,
} from "../../../firebase";
import { Alert, Snackbar, Typography } from "@mui/material";
import {
  RedGameLoadingButton,
  RoundedCard,
} from "../../MUI_Components/Components";
import AccountMenu from "../AccountMenu";
import dayjs from "dayjs";
import "../Pages.css";
import ResultsPage from "./ResultsPage";
import { DeleteDialog, LeaveGameDialog } from "./ProfileDialogs";
import { getTimestampIn5Hours } from "../../../utils/utils";
import AvatarContainer from "./AvatarContainer";
import MainAvatar from "./Components/MainAvatar";
import NewGame from "./Components/NewGame";
import GameConfiguration from "./Components/GameConfiguration";

const Profile = (props) => {
  const navigate = useNavigate();
  const { currentUser, logout } = useAuth();

  const [error, setError] = useState("");
  const [userDetails, setUserDetails] = useState(null);
  const [host, setHost] = useState(null);
  const [open, setOpen] = useState(false);
  const [gameStarted, setGameStarted] = useState(false);
  const [leaveGameOpen, setLeaveGameOpen] = useState(false);
  const [userImage, setUserImage] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [hideLeaderboardMins, setHideLeaderboardMins] = useState(null);
  const [loading, setLoading] = useState(false);
  const [packList, setPackList] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [value, setValue] = useState(dayjs(getTimestampIn5Hours()));

  const handleCopyGameCode = () => {
    const textToCopy = props.user.game;
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => setOpenToast(true))
        .catch(console.error);
    }
  };

  const handleToastClose = (_, reason) => {
    if (reason !== "clickaway") setOpenToast(false);
  };

  const handleLeaveGame = useCallback(async () => {
    setLoading(true);
    setHost(null);
    props.clearChallenges();
    setGameStarted(false);
    setLeaveGameOpen(false);
    setError("");
    try {
      await leaveGame(currentUser.uid, props.user.game);
      props.setGame([]);
    } catch {
      setError("Failed to Leave Game");
    } finally {
      setLoading(false);
    }
  }, [currentUser.uid, props]);

  const getUserDetails = useCallback(async () => {
    try {
      const userData = await getUserData(currentUser.uid);
      setUserDetails(userData);
      setUserImage(userData.image);
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      setLoaded(true);
    }
  }, [currentUser.uid]);

  useEffect(() => {
    getUserDetails();
  }, [getUserDetails]);

  useEffect(() => {
    if (props?.game?.host?.started !== "false") setGameStarted(true);
  }, [props.game?.host]);

  useEffect(() => {
    const query = ref(getDB(), "/packs/");
    return onValue(query, (snapshot) => {
      if (snapshot.exists()) setPackList(Object.entries(snapshot.val()));
    });
  }, []);

  useEffect(() => {
    if (props?.user?.game && props?.user?.game !== "false") {
      const query = ref(getDB(), `/games/${props.user.game}/host`);
      return onValue(query, (snapshot) => {
        const data = snapshot.val();
        if (!data) {
          handleLeaveGame();
          props.setGame([]);
        } else {
          setHost(data.host);
          setEndTime(data.endTime);
          console.log("first", data);
          setHideLeaderboardMins(data?.hideLeaderboardMins);
        }
      });
    }
  }, [props.user.game, props, handleLeaveGame]);

  const handleCreateGame = async () => {
    setError("");
    setHost(currentUser.uid);
    try {
      await createGame(currentUser.uid, props.user.name);
    } catch {
      setError("Failed to Create Game");
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteGame = async () => {
    handleClose();
    props.clearChallenges();
    setError("");
    try {
      await moveGameToPrevious(currentUser.uid, props.user.game);
      props.setGame([]);
      setGameStarted(false);
    } catch {
      setError("Failed to Delete Game");
    }
  };

  const handleLogout = async () => {
    setError("");
    try {
      await logout();
      navigate("/login");
    } catch {
      setError("Failed to Log Out");
    }
  };

  const handleViewResults = () => setShowResults(true);

  return (
    loaded && (
      <>
        <div className="settingsContainer">
          {userDetails?.name && (
            <AccountMenu
              username={userDetails.name.charAt(0)}
              handleUpdateProfile={() => navigate("/update-profile")}
              handleLogout={handleLogout}
              userImage={userImage}
            />
          )}
        </div>

        <div className="profileMainContainer">
          <div className="profileMain">
            <div className="profilePicture">
              <MainAvatar userImage={userImage} userDetails={userDetails} />
            </div>

            <RoundedCard className="profilePaper">
              <div className="paperBottom">
                <div className="profileItems">
                  {error && (
                    <Alert severity="error" sx={{ marginBottom: "20px" }}>
                      {error}
                    </Alert>
                  )}

                  {props.user.game === "false" ? (
                    <NewGame
                      setError={setError}
                      user={props.user}
                      handleCreateGame={handleCreateGame}
                      role={props.role}
                    />
                  ) : (
                    <>
                      <Typography
                        variant="subtitle2"
                        sx={{ textAlign: "center" }}
                      >
                        Game Code
                      </Typography>
                      <Typography
                        variant="h3"
                        sx={{ textAlign: "center", marginBottom: "20px" }}
                        onClick={handleCopyGameCode}
                        style={{ cursor: "pointer" }}
                      >
                        {props.user.game}
                      </Typography>
                    </>
                  )}

                  <AvatarContainer user={props.user} />

                  <GameConfiguration
                    {...props}
                    host={host}
                    name="hello"
                    value={value}
                    endTime={endTime}
                    hideLeaderboardMins={hideLeaderboardMins}
                    gameStarted={gameStarted}
                    setValue={setValue}
                    packList={packList}
                    handleViewResults={handleViewResults}
                    handleClickOpen={() => setOpen(true)}
                    setError={setError}
                  />

                  {props?.game?.host &&
                    currentUser?.uid !== host &&
                    props.gameStatus === "false" && (
                      <RedGameLoadingButton
                        fullWidth
                        variant="contained"
                        onClick={() => setLeaveGameOpen(true)}
                        sx={{ marginTop: "10px" }}
                        color="error"
                        loading={loading}
                      >
                        Leave Game
                      </RedGameLoadingButton>
                    )}

                  {props?.game?.host &&
                    currentUser?.uid !== host &&
                    props.gameStatus === "ended" && (
                      <>
                        <Typography variant="h4">Game Over!</Typography>
                        <Typography variant="h7">Host has results.</Typography>
                      </>
                    )}
                </div>
              </div>
            </RoundedCard>
          </div>
        </div>

        {showResults && <ResultsPage setShowResults={setShowResults} />}

        <DeleteDialog
          open={open}
          handleClose={() => setOpen(false)}
          handleDeleteGame={handleDeleteGame}
        />
        <LeaveGameDialog
          leaveGameOpen={leaveGameOpen}
          handleLeaveGameClose={() => setLeaveGameOpen(false)}
          handleLeaveGame={handleLeaveGame}
        />
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          open={openToast}
          sx={{ width: "150px" }}
          autoHideDuration={6000}
          onClose={handleToastClose}
        >
          <Alert
            onClose={handleToastClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            Copied!
          </Alert>
        </Snackbar>
      </>
    )
  );
};

export default Profile;
