import { Avatar, Typography } from "@mui/material";
import React from "react";

const MainAvatar = (props) => {
  return (
    <>
      {props.userImage ? (
        <div className="avatarWithText">
          <Avatar
            sx={{ width: "120px", height: "120px" }}
            src={props.userImage}
          />
          <Typography
            variant="h6"
            style={{
              fontSize: "30px",
              color: "black",
              textAlign: "center",
            }}
          >
            {props.userDetails?.name}
          </Typography>
        </div>
      ) : (
        <div className="avatarWithText">
          <Avatar sx={{ width: "120px", height: "120px" }}>
            {props.userDetails?.name.charAt(0) || ""}
          </Avatar>
          <Typography
            variant="h6"
            style={{
              fontSize: "30px",
              color: "black",
              textAlign: "center",
            }}
          >
            {props.userDetails?.name}
          </Typography>
        </div>
      )}
    </>
  );
};

export default MainAvatar;
