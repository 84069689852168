import { useState, useEffect } from "react";
import { ref, onValue } from "firebase/database";
import { getDB } from "../../firebase.js";
import { CircularProgress, Typography } from "@mui/material";
import coinsImage from "../../assets/coins.png";
import gemsImage from "../../assets/curseGem.png";
import CurseLineItem from "./Curses/CurseLineItem.js";
import BoltIcon from "@mui/icons-material/Bolt";
import GameOver from "./HoldingPages/GameOver.js";
import { useAuth } from "../../contexts/AuthContext.js";
import { TitleBox } from "../MUI_Components/Components.js";

function CurseIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
      width="35"
      height="35"
      backgroundColor="#AC3BF0"
    >
      <path
        fill="#ac3bf1"
        stroke="white"
        d="M368 128c0 44.4-25.4 83.5-64 106.4V256c0 17.7-14.3 32-32 32H176c-17.7 0-32-14.3-32-32V234.4c-38.6-23-64-62.1-64-106.4C80 57.3 144.5 0 224 0s144 57.3 144 128zM168 176a32 32 0 1 0 0-64 32 32 0 1 0 0 64zm144-32a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zM3.4 273.7c7.9-15.8 27.1-22.2 42.9-14.3L224 348.2l177.7-88.8c15.8-7.9 35-1.5 42.9 14.3s1.5 35-14.3 42.9L295.6 384l134.8 67.4c15.8 7.9 22.2 27.1 14.3 42.9s-27.1 22.2-42.9 14.3L224 419.8 46.3 508.6c-15.8 7.9-35 1.5-42.9-14.3s-1.5-35 14.3-42.9L152.4 384 17.7 316.6C1.9 308.7-4.5 289.5 3.4 273.7z"
      />
    </svg>
  );
}

const Store = (props) => {
  const { currentUser } = useAuth();
  const [curses, setCurses] = useState([]);
  const [purchased, setPurchased] = useState([]);
  const [isError, setIsError] = useState(false);
  const [open, setOpen] = useState(false);
  const [members, setMembers] = useState([]);
  const [pack, setPack] = useState();
  const [userGameStats, setUserGameStats] = useState();
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState();

  useEffect(() => {
    if (props.user.game === "false") {
      return;
    }
    const gameQuery = ref(
      getDB(),
      "/games/" + props.user.game + "/players/" + currentUser.uid
    );
    onValue(gameQuery, (snapshot) => {
      const data = snapshot.val();
      if (snapshot.exists() && data !== null) {
        setUserGameStats(data);
      }
    });
  }, [currentUser.uid, props.user.game]);

  useEffect(() => {
    if (props.user.game !== "false") {
      setIsError(false);
      setPack(props.selectedPack);
      getCurses(props.selectedPack);
      const query = ref(getDB(), "/games/" + props.user.game);
      return onValue(query, (snapshot) => {
        const data = snapshot.val();
        if (snapshot.exists()) {
          const mems = Object.keys(data.players).map((userID) => {
            return {
              id: userID,
              username: data.players[userID].username,
            };
          });
          setMembers(mems);
          console.log("mems", mems);
        }
      });
    }
  }, [props.user.game, props.selectedPack]);

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      uids: Object.values(members).map((member) => member.id),
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://getuserimages-nykygdtd7a-uc.a.run.app", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        setImages(JSON.parse(result));
        console.log("images", result);
      })
      .catch((error) => console.log("error", error));
  }, [members]);

  function getCurses(pack) {
    const cursesPath = `/curses/${pack}/`;
    const query = ref(getDB(), cursesPath);
    return onValue(query, (snapshot) => {
      let data = snapshot.val();
      if (snapshot.exists() && Object.values(data).length > 0) {
        data = Object.values(
          Object.keys(data).map((key) => ({
            ...data[key],
            curseId: parseInt(key),
          }))
        ).filter((item) => item !== null);

        // const indexes = [0, 1, 2, 3, 5, 4];
        // const orderedCurses = indexes.map((i) => data[i]);
        setCurses(data);
      }
    });
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    console.log(open);
  };

  useEffect(() => {
    const query = ref(
      getDB(),
      "/games/" + props.user.game + "/players/" + props.uid + "/purchasedItems"
    );
    return onValue(query, (snapshot) => {
      let data = snapshot.val();
      if (snapshot.exists() && Object.values(data).length > 0) {
        data = Object.values(
          Object.keys(data).map((key) => ({
            ...data[key],
            curseId: parseInt(key),
          }))
        ).filter((item) => item !== null);
        setPurchased(data);
      } else {
        setPurchased([]);
      }
    });
  }, [props.uid, props.user.game]);

  return (
    <>
      <TitleBox>
        <Typography variant="h3" className="gradient-text">
          STORE
        </Typography>
      </TitleBox>
      <div className="coinsContainer">
        <div className="coinsText">
          {loading === "coins" ? <CircularProgress /> : userGameStats?.coins}
        </div>
        <img className="coinsImg" alt="coins" src={coinsImage} />
      </div>
      <div className="curseCardContainer">
        <div className="subMenuWithIcon">
          <BoltIcon sx={{ color: "yellow", fontSize: "40px" }} />
          <Typography variant="h4">Powerups</Typography>
        </div>

        {curses
          ?.filter((curse) => curse?.type === "power")
          .map((curse) => {
            console.log(
              "first",
              props?.completedChallenges?.filter(
                (challenge) => challenge?.approvedByGM === false
              ).length > 0
            );
            return (
              <CurseLineItem
                curse={curse}
                pack={pack}
                key={curse.curseId}
                challenges={props.challenges}
                pendingChallenges={
                  props?.completedChallenges?.filter(
                    (challenge) => challenge?.approvedByGM === false
                  ).length > 0
                }
                purchased={purchased}
                setOpen={setOpen}
                isError={isError}
                user={props.user}
                uid={props.uid}
                handleClose={handleClose}
                setLoading={setLoading}
                coins={userGameStats?.coins || 0}
              />
            );
          })}
      </div>

      <div className="gemsContainer">
        <div className="coinsText">
          {loading === "gems" ? <CircularProgress /> : userGameStats?.gems}
        </div>
        <img className="gemsImg" alt="gems" src={gemsImage} />
      </div>
      <div className="curseCardContainer">
        <div className="subMenuWithIcon">
          <CurseIcon />
          <Typography variant="h4" sx={{ marginLeft: "10px" }}>
            Curses
          </Typography>
        </div>
        {curses?.length > 0 &&
          curses
            .filter((curse) => curse?.type === "curse")
            .map((curse) => {
              return (
                <CurseLineItem
                  curse={curse}
                  key={curse.curseId}
                  pack={pack}
                  challenges={props.challenges}
                  members={members}
                  purchased={purchased}
                  setOpen={setOpen}
                  isError={isError}
                  user={props.user}
                  uid={props.uid}
                  handleClose={handleClose}
                  setLoading={setLoading}
                  gems={userGameStats?.gems || 0}
                  images={images}
                />
              );
            })}
      </div>
      <div className="footerBuffer" />
    </>
  );
};

export default Store;
