import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  Divider,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import CountdownTimer from "./CountdownTimer";
import { useAuth } from "../../../../contexts/AuthContext";
import {
  GameButton,
  GreenGameButton,
  RedGameLoadingButton,
} from "../../../MUI_Components/Components";
import {
  convertObjectToArrayWithId,
  endGame,
  futureTime,
} from "../../../../utils/utils";
import { startGame } from "../../../../firebase";
import GMComponent from "../GMProfile/GMComponent";
import DurationPicker from "./DurationPicker";
import { onValue, ref } from "firebase/database";
import { getDB } from "../../../../firebase";

const GameConfiguration = ({
  user,
  gameStatus,
  host,
  value,
  endTime,
  setError,
  setGameEnded,
  handleClickOpen,
  handleViewResults,
  gameStarted,
  packList,
  hideLeaderboardMins,
}) => {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [packName, setPackName] = useState("ottawa2024");
  const [hours, setHours] = useState(6);
  const [minutes, setMinutes] = useState(0);
  const [hideMins, setHideMins] = useState(30);
  const [gmRequests, setGMRequests] = useState([]);
  const [gmReqImages, setGMReqImages] = useState([]);

  const getRequestImages = useCallback((uids) => {
    // Fetch images only when necessary
    fetch("https://getuserimages-nykygdtd7a-uc.a.run.app", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ uids }),
    })
      .then((response) => response.text())
      .then((result) => setGMReqImages(JSON.parse(result)))
      .catch((error) => console.log("error", error));
  }, []);

  useEffect(() => {
    if (user.game !== "false") {
      const query = ref(getDB(), "/games/" + user.game);
      return onValue(query, (snapshot) => {
        const data = snapshot.val();
        if (snapshot.exists() && data.gameMasterRequests) {
          const requests = data.gameMasterRequests;
          if (Object.keys(requests).length > 0) {
            setGMRequests(requests);
            getRequestImages(Object.keys(requests));
          }
        }
      });
    }
  }, [user.game, getRequestImages]);

  const handleStartGame = async () => {
    setError("");
    try {
      const hasGameMaster = convertObjectToArrayWithId(gmRequests).some(
        (request) => request.status === "approved"
      );
      await startGame(
        user.game,
        futureTime(hours, minutes),
        packName,
        hideMins,
        hasGameMaster
      );
      setGameEnded(null);
    } catch (error) {
      console.log("error", error);
      setError("Failed to Start Game");
    }
  };

  const handleEndGame = useCallback(() => {
    setLoading(true);
    endGame(user.game);
  }, [user.game]);

  const isHost = useMemo(() => currentUser?.uid === host, [currentUser, host]);

  return (
    <>
      {gameStatus === "true" && (
        <CountdownTimer
          endTime={endTime}
          hideLeaderboardMins={hideLeaderboardMins}
          sx={{ marginBottom: "30px" }}
        />
      )}
      {user?.game && isHost && value && endTime && (
        <>
          {gameStatus === "false" && (
            <div style={{ width: "100%" }}>
              <DurationPicker
                hours={hours}
                minutes={minutes}
                setHours={setHours}
                setMinutes={setMinutes}
                hideMins={hideMins}
                setHideMins={setHideMins}
                packName={packName}
                packList={packList}
                handleChange={(event) => setPackName(event.target.value)}
              />
            </div>
          )}
          <Divider />

          {gameStatus === "false" && (
            <GreenGameButton
              fullWidth
              variant="contained"
              onClick={handleStartGame}
              sx={{ marginTop: "10px" }}
              color="success"
              disabled={gameStarted === true}
            >
              Start Game
            </GreenGameButton>
          )}
          {gameStatus === "ended" && (
            <>
              <Typography variant="h4">Game Over!</Typography>
              <GameButton
                fullWidth
                variant="contained"
                onClick={handleViewResults}
                sx={{ marginTop: "10px", height: "80px" }}
              >
                View Results
              </GameButton>
            </>
          )}
          {(gameStatus === "false" || gameStatus === "ended") && (
            <RedGameLoadingButton
              fullWidth
              loading={loading}
              variant="contained"
              onClick={handleClickOpen}
              sx={{ marginTop: "30px" }}
              color="error"
            >
              Close Game
            </RedGameLoadingButton>
          )}

          <GMComponent
            gameStarted={gameStarted}
            user={user}
            gmRequests={gmRequests}
            setGMRequests={setGMRequests}
            setGMReqImages={setGMReqImages}
            gmReqImages={gmReqImages}
          />

          {gameStatus === "true" && (
            <>
              <RedGameLoadingButton
                fullWidth
                variant="contained"
                loading={loading}
                onClick={() => setConfirmOpen(true)}
                sx={{ marginTop: "30px" }}
                color="error"
              >
                End Game
              </RedGameLoadingButton>
              <Dialog open={confirmOpen} onClose={() => setConfirmOpen(false)}>
                <DialogTitle>Confirm End Game</DialogTitle>
                <DialogContent>
                  Are you sure you want to end the game? This action cannot be
                  undone.
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setConfirmOpen(false)} color="primary">
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      setConfirmOpen(false);
                      handleEndGame();
                    }}
                    color="error"
                    variant="contained"
                  >
                    End Game
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          )}
        </>
      )}
    </>
  );
};

export default GameConfiguration;
