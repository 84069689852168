import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import logo from "../../../../assets/2024logo.png";
import { RoundedCard } from "../../../MUI_Components/Components";
import { auth } from "../../../../firebase"; // Ensure the correct path to your firebase config
import { useNavigate } from "react-router-dom";

const Registered = () => {
  const [responseData, setResponseData] = useState(null);
  const navigate = useNavigate();

  const fetchUsers = async () => {
    try {
      const user = auth.currentUser;
      if (!user) {
        console.error("User not authenticated");
        return;
      }

      const idToken = await user.getIdToken();

      const response = await fetch(
        "https://us-central1-jetlag-b57f5.cloudfunctions.net/listResponsesReq",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log("listUsers result:", result.registered); // Log result
      setResponseData(result.registered);
    } catch (error) {
      console.error("Error fetching listResponses:", error); // Log error
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <div
      style={{
        width: "100%",
        position: "absolute",
        left: 0,
        top: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <RoundedCard sx={{ width: "90%", marginTop: "20" }}>
        <Container component="main" maxWidth="md" sx={{}}>
          <Box
            sx={{
              marginTop: 3,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div>
              <img
                src={logo}
                alt="mediocre race logo"
                style={{ width: "100px", cursor: "pointer" }}
                onClick={() => {
                  navigate("/");
                }}
              />
            </div>
            <Typography variant="h4" sx={{ mt: 3, textAlign: "center" }}>
              Mediocre Race Respondees
            </Typography>
            <Box sx={{ mt: 3, textAlign: "center", width: "100%" }}>
              {responseData ? (
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Email</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell align="right">Participant</TableCell>
                        <TableCell align="right">Game Master</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.keys(responseData).map((email) => (
                        <TableRow key={email}>
                          <TableCell component="th" scope="row">
                            {responseData[email].email}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {responseData[email].fullname}
                          </TableCell>
                          <TableCell align="right">
                            {responseData[email].participant ? "Yes" : "No"}
                          </TableCell>
                          <TableCell align="right">
                            {responseData[email].volunteerGameMaster
                              ? "Yes"
                              : "No"}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Typography variant="body1">Loading responses...</Typography>
              )}
            </Box>
          </Box>
        </Container>
      </RoundedCard>
    </div>
  );
};

export default Registered;
