import { Button, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./PreviousGames.css";
import Pagination from "@mui/material/Pagination";
import { getPreviousGames } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import PreviousGame from "./PreviousGame";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";
const PreviousGames = () => {
  const { currentUser } = useAuth();
  const [games, setGames] = useState();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const handleChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    try {
      getPreviousGames(currentUser.uid).then((res) => {
        console.log("res", res)
        setGames(res.reverse());
        setLoading(false);
        console.log(res);
      });
    } catch (error) {
      console.log("error", error)
    }
   
  }, [currentUser.uid]);

  return (
    <Paper sx={{left: 0, top: 0, position: "absolute", width: "100dvw", height: "100dvh"}}>
      <Button
        startIcon={<ArrowBackIosIcon />}
        sx={{ margin: "4px" }}
        onClick={() => {
          navigate("/");
        }}
      >
        Back
      </Button>
      {(!loading && games.length > 0) && (
        <>
          <PreviousGame data={games[page - 1]} />
          <div className="pagination">
            <Pagination
              count={games.length}
              page={page}
              onChange={handleChange}
            />
          </div>
        </>
      )}
      {(!loading && games.length === 0) && (
        <div style={{width: "100%", height: "100dvh", display: "flex", justifyContent: "center", alignItems: "center"}}>
          <Typography>No Previous Games Found</Typography>
        </div>
      )}
    </Paper>
  );
};

export default PreviousGames;
