import React from "react";
import {
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";
import "./PreviousGames.css"

const PreviousGame = ({ data }) => {
  // Sort players by coins to display on the leaderboard
  const sortedPlayers = data?.leaderboard
    ? Object.entries(data?.leaderboard).sort((a, b) => b[1].coins - a[1].coins)
    : [];

  // Convert the GMT date string to a local date-time string
  const localDate = new Date(data.host.endTime).toLocaleString();

  return (
    <div style={{left: 0, width: "100%", height: "auto", overflow: "auto", position:"absolute"}}>
      <Paper elevation={3} style={{ padding: "20px" }}>
        <Typography variant="h4" gutterBottom>
          Previous Game Details
        </Typography>
        <Typography variant="h6">End Time: {localDate}</Typography>
        <Typography variant="h6">
          Host: {data.players[data.host.host].username}
        </Typography>

        <Divider style={{ margin: "20px 0" }} />

        {sortedPlayers.length > 0 ? (
          <>
            <Typography variant="h5" gutterBottom>
              Leaderboard
            </Typography>
            <List style={{ maxHeight: "300px", overflowY: "auto" }}>
              {sortedPlayers.map((player, index) => (
                <ListItem key={player[0]}>
                  <ListItemText
                    primary={`${index + 1}. ${
                      data.players[player[0]].username
                    }`}
                    secondary={`Coins: ${player[1].coins}`}
                  />
                </ListItem>
              ))}
            </List>
          </>
        ) : null}

        <Divider style={{ margin: "20px 0" }} />

        {data?.stats ? (
          <>
            <Typography variant="h5" gutterBottom>
              Player Stats
            </Typography>
            {Object.entries(data.stats).map(([playerId, stats]) => (
              <div key={playerId}>
                <Typography variant="h6" gutterBottom>
                  {data.players[playerId].username}
                </Typography>
                {Object.entries(stats).map(([statName, value]) => (
                  <Typography key={statName}>
                    {statName}: {value}
                  </Typography>
                ))}
              </div>
            ))}
          </>
        ) : null}

        {sortedPlayers.length === 0 && !data?.stats ? (
          <Typography variant="h5" gutterBottom>
            Game Ended before Leaderboard or Stats Created
          </Typography>
        ) : null}
      </Paper>
    </div>
  );
};

export default PreviousGame;
