import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import { uploadImageToStorage } from "../../firebase";
import { getDB } from "../../firebase";
import { ref, onValue, update } from "firebase/database";
import Badge from "@mui/material/Badge";
import CreateIcon from "@mui/icons-material/Create";
import {
  GameButton,
  RoundedCard,
  RoundedTextField,
} from "../MUI_Components/Components";

const UpdateProfile = () => {
  const { currentUser, updatePassword, updateEmail } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [currentUserExtras, setCurrentUserExtras] = useState();

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  function handleCancel() {
    navigate("/");
  }

  useEffect(() => {
    const query = ref(getDB(), "/users/" + currentUser.uid);
    return onValue(query, (snapshot) => {
      const data = snapshot.val();

      if (snapshot.exists()) {
        setCurrentUserExtras(data);
      }
    });
  }, []);

  function handleUploadProfile(event) {
    const file = event.target.files[0];
    if (file) {
      setLoading("image");
      uploadImageToStorage(currentUser.uid, file).then((res) => {
        setLoading(false);
      });
    }
  }

  function updatePhoneForUser(userId, newPhoneNumber) {
    const userRef = ref(getDB(), `users/${userId}`);
    update(userRef, { phone: newPhoneNumber })
      .then(() => {
        console.log("Phone number updated successfully!");
      })
      .catch((error) => {
        console.error("Error updating phone number: ", error);
      });
  }

  function updateNameForUser(userId, newName) {
    const userRef = ref(getDB(), `users/${userId}`);
    update(userRef, { name: newName })
      .then(() => {
        console.log("Name updated successfully!");
      })
      .catch((error) => {
        console.error("Error updating phone number: ", error);
      });
  }

  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    const data = new FormData(e.currentTarget);
    if (data.get("password") !== data.get("confirm-password")) {
      return setError("Passwords Do Not Match");
    }

    const promises = [];
    if (data.get("email") !== currentUser.email) {
      promises.push(updateEmail(data.get("email")));
    }
    console.log("name", data.get("name"));
    if (data.get("name") !== currentUser.name) {
      promises.push(updateNameForUser(currentUser.uid, data.get("name")));
    }
    if (data.get("phone") !== currentUserExtras.phone) {
      updatePhoneForUser(currentUser.uid, data.get("phone"));
    }
    if (data.get("password")) {
      promises.push(updatePassword(data.get("password")));
    }

    Promise.all(promises)
      .then(() => {
        navigate("/");
      })
      .catch(() => {
        setError("Failed to update account");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <RoundedCard>
      {currentUserExtras && (
        <Container component="main" maxWidth="xs">
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div className="profilePictureSettings">
              {currentUserExtras?.image && loading !== "image" && (
                <Badge
                  overlap="circular"
                  sx={{
                    border: "dashed",
                    borderRadius: "50%",
                    borderWidth: "3px",
                  }}
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  badgeContent={
                    <CreateIcon fontSize="large" sx={{ color: "#3F2E3E" }} />
                  }
                >
                  <label htmlFor="profileUpload">
                    <Avatar
                      sx={{ width: "90px", height: "90px" }}
                      src={currentUserExtras?.image}
                    />
                  </label>
                  <input
                    id="profileUpload"
                    type="file"
                    hidden
                    onChange={handleUploadProfile}
                  />
                </Badge>
              )}
              {!currentUserExtras?.image && loading !== "image" && (
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  badgeContent={
                    <CreateIcon fontSize="large" sx={{ color: "#3F2E3E" }} />
                  }
                >
                  <label htmlFor="profileUpload">
                    <Avatar sx={{ width: "90px", height: "90px" }}>
                      {currentUserExtras?.name.charAt(0) || ""}
                    </Avatar>
                  </label>
                  <input
                    id="profileUpload"
                    type="file"
                    hidden
                    onChange={handleUploadProfile}
                  />
                </Badge>
              )}
              {currentUserExtras?.image && loading === "image" && (
                <Badge
                  overlap="circular"
                  sx={{
                    border: "dashed",
                    borderRadius: "50%",
                    borderWidth: "3px",
                  }}
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  badgeContent={
                    <CreateIcon fontSize="large" sx={{ color: "#3F2E3E" }} />
                  }
                >
                  <label htmlFor="profileUpload">
                    <Avatar sx={{ width: "90px", height: "90px" }}>
                      <CircularProgress />
                    </Avatar>
                  </label>
                  <input
                    id="profileUpload"
                    type="file"
                    hidden
                    onChange={handleUploadProfile}
                  />
                </Badge>
              )}
            </div>
            <Typography variant="h4" sx={{ marginTop: "10px" }}>
              Update Profile
            </Typography>
            {error && <Alert severity="error">{error}</Alert>}
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <RoundedTextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                defaultValue={currentUser?.email}
                autoComplete="email"
                disabled={loading}
              />
              <RoundedTextField
                margin="normal"
                required
                fullWidth
                id="name"
                disabled={loading}
                label="Team Name"
                name="name"
                defaultValue={currentUserExtras?.name}
                autoComplete="username"
              />
              <RoundedTextField
                margin="normal"
                required
                fullWidth
                id="phone"
                label="Phone Number"
                name="phone"
                defaultValue={currentUserExtras?.phone}
                autoComplete="phone"
                disabled={loading}
              />
              <RoundedTextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                id="password"
                disabled={loading}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <RoundedTextField
                margin="normal"
                required
                fullWidth
                disabled={loading}
                name="confirm-password"
                label="Confirm Password"
                type={showPassword ? "text" : "password"}
                id="confirm-password"
                autoComplete="confirm-password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexWrap: "wrap",
                }}
              >
                <GameButton type="submit" disabled={loading}>
                  Save
                </GameButton>
                <Button
                  disabled={loading}
                  sx={{ marginTop: "20px" }}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </div>
            </Box>
          </Box>
        </Container>
      )}
    </RoundedCard>
  );
};

export default UpdateProfile;
