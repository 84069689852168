import React, { useState } from "react";
import { Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import coinsImage from "../../assets/coins.png";
import "./Pages.css";
import CircularProgress from "@mui/material/CircularProgress";
import { GameButtonCustom } from "../MUI_Components/Components";

const VetoButton = styled(GameButtonCustom)(({ theme }) => ({
  color: "white",
  height: "40px",
  width: "50%",
  marginTop: "15px",
  marginRight: "5px",
  backgroundColor: grey[600],
  "&:hover": {
    backgroundColor: grey[700],
  },
}));

const CurseCard = (props) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [openVeto, setOpenVeto] = React.useState(false);

  function complete() {
    handleClose();
    setLoading(props.curse.title);
    props.completeCurse(props.curse.curseUniqueId);
  }

  function veto() {
    handleCloseVeto();
    props.vetoCurse(props.curse.curseId, props.curse.curseUniqueId);
    console.log("veto");
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickOpenVeto = () => {
    setOpenVeto(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseVeto = () => {
    setOpenVeto(false);
  };

  return (
    <div className="curseCardContainer">
      {loading === props.curse.curseName ? (
        <div className="LoadingOverCard">
          <CircularProgress />
        </div>
      ) : null}
      <div
        className={`curseCard ${
          loading === props.curse.curseName ? "loadingcurse" : ""
        }`}
      >
        <Typography
          variant="h5"
          sx={{ marginBottom: "5px", fontWeight: "bold" }}
        >
          {props.curse.curseName}
        </Typography>
        <Typography variant="h6" sx={{ fontStyle: "italic" }}>
          Cursed by {props.curse.cursedBy}
        </Typography>
        <Typography variant="h6">{props.curse.curseDescription}</Typography>
        <div className="buttonContainer">
          <VetoButton
            variant="contained"
            color="primary"
            disabled={props.completed}
            onClick={() => {
              handleClickOpenVeto();
            }}
            sx={{width: "170px", height: "40px", marginTop: "20px", marginRight: "10px"}}

          >
            <div className="vetoText">Veto</div>
            <img
              src={coinsImage}
              alt="coins"
              className="purchaseButtonImage"
            ></img>

            {props.curse.veto}
          </VetoButton>
          <GameButtonCustom
            variant="contained"
            color="primary"
            sx={{width: "170px", height: "40px", marginTop: "20px"}}
            disabled={props.completed}
            onClick={() => {
              handleClickOpen();
            }}
          >
            {props.completed ? "Completed" : "Complete"}
          </GameButtonCustom>
        </div>
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Complete curse"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Officially Submit completion of this task! Please make sure you save
            proof of completion for later.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <GameButtonCustom onClick={complete} autoFocus variant="contained">
            Complete
          </GameButtonCustom>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openVeto}
        onClose={handleCloseVeto}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Veto curse"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Pay {props.curse.veto} coins to skip this curse?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseVeto}>Cancel</Button>
          <GameButtonCustom onClick={veto} autoFocus variant="contained">
            Veto
          </GameButtonCustom>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CurseCard;
