import { Check, DoNotDisturb } from "@mui/icons-material";
import { Avatar, IconButton, Typography } from "@mui/material";
import React from "react";
import { approveGM } from "../../../../firebase";

const GMRequests = (props) => {
  return (
    <>
      {Object.values(props.gmRequests).filter((request) => !request.status)
        .length > 0 && (
        <>
          <Typography
            variant="subtitle1"
            sx={{ textAlign: "center", marginTop: "20px" }}
          >
            Game Master Requests
          </Typography>
          <div className="gmRequestContainer">
            {Object.keys(props.gmRequests).length > 0 &&
              Object.values(props.gmRequests).map((member, index) => {
                if (!member.status) {
                  return (
                    <div className="gmRequest" key={index}>
                      <div className="gmProfileName">
                        {props.gmReqImages &&
                        props.gmReqImages[member.uid] !== null ? (
                          <Avatar
                            src={props.gmReqImages[member.uid]}
                            alt={member.username}
                          >
                            {member.username
                              ? member.username.substring(0, 1)
                              : ""}
                          </Avatar>
                        ) : (
                          <Avatar alt="profile avatar">
                            {member.username
                              ? member.username.substring(0, 1)
                              : ""}
                          </Avatar>
                        )}
                        <Typography
                          variant="h6"
                          style={{
                            fontSize: "15px",
                            color: "black",
                            marginLeft: "15px",
                          }}
                        >
                          {member.username}
                        </Typography>
                      </div>
                      <div>
                        <IconButton
                          aria-label="check"
                          variant="contained"
                          sx={{ marginRight: "20px" }}
                          onClick={() => {
                            approveGM(member.uid, props.user.game, true);
                          }}
                        >
                          <Check />
                        </IconButton>
                        <IconButton
                          aria-label="decline"
                          variant="contained"
                          onClick={() => {
                            approveGM(member.uid, props.user.game, false);
                          }}
                        >
                          <DoNotDisturb />
                        </IconButton>
                      </div>
                    </div>
                  );
                }
                return (
                  <Typography variant="subtitle2">
                    There are no Game Master requests
                  </Typography>
                );
              })}
          </div>
        </>
      )}
    </>
  );
};

export default GMRequests;
