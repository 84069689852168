import React, { useEffect, useState } from "react";
import "./Pages.css";
import ChallengeCard from "./ChallengeCard.js";
import { styled } from "@mui/system";
import CircularProgress from "@mui/material/CircularProgress";
import { AlertTitle, Typography } from "@mui/material";
import GameOver from "./HoldingPages/GameOver.js";
import { delay } from "../../utils/utils.js";
import { TitleBox } from "../MUI_Components/Components.js";
import Alert from "@mui/material/Alert";

const ChallengeCardStyled = styled("div")({
  backgroundColor: "#F3F0E6",
  borderRadius: "2px",
  border: "5px",
  color: "black",
  borderStyle: "solid",
  marginLeft: "20px",
  marginRight: "20px",
});

const PendingChallengeCardStyled = styled("div")({
  opacity: "0.7",
  backgroundColor: "#F3F0E6",
  borderRadius: "2px",
  border: "5px",
  color: "black",
  borderStyle: "solid",
  marginLeft: "20px",
  marginRight: "20px",
});

const CompletedChallengeCardStyled = styled("div")({
  opacity: "0.3",
  backgroundColor: "#F3F0E6",
  borderRadius: "2px",
  border: "5px",
  color: "black",
  borderStyle: "solid",
  marginLeft: "20px",
  marginRight: "20px",
});

// Inside the Challenges component
const Challenges = (props) => {
  const [completed, setCompleted] = useState();
  const [loadingMessage, setLoadingMessage] = useState([
    "Finding Challenges",
    "Configuring Gamemode",
    "Giving Charlie the worst ones",
    "Making Calum Express emotion",
    "Finalizing",
    "Finalizing.",
    "Finalizing..",
    "Finalizing...",
  ]);

  function completeChallenge(challengeId) {
    console.log(challengeId, props.uid, props.user.game);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      gameId: props.user.game,
      playerId: props.uid,
      challengeId: challengeId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://completechallenge-nykygdtd7a-uc.a.run.app", requestOptions)
      .then((response) => response.text())
      .then((result) => isResAnError(result))
      .catch((error) => console.log("error", error));
  }

  function isResAnError(res) {
    console.log("RES", res);
    if (res === "Challenge completed successfully.") {
      console.log("RES", "SHOWING CON");
      showAndHideConfetti();
    }
  }

  async function showAndHideConfetti() {
    console.log("RES", "SHOWING");
    props.setShowConfetti(true);
    delay(3000).then(() => props.setShowConfetti(false));
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (loadingMessage.length > 1 && (props.challenges || []).length === 0) {
        setLoadingMessage((prevMessages) => prevMessages.slice(1));
        console.log("first", loadingMessage.length);
      }
    }, 3000);
    return () => clearInterval(interval);
  }, [loadingMessage, props.challenges]);

  return (
    <>
      <TitleBox>
        <Typography variant="h3" className="gradient-text">
          Challenges
        </Typography>
      </TitleBox>
      {props.pendingCurses?.filter((curse) => curse.approvedByGM === false)
        .length > 0 && (
        <Alert variant="filled" severity="error">
          You cannot complete challenges until all curses have been approved by
          your GM
        </Alert>
      )}
      {props && (
        <div>
          {props?.challenges.map((challenge, index) => (
            <ChallengeCardStyled
              key={index}
              sx={{
                marginBottom: "10px",
                padding: "30px 30px 20px 30px",
                borderColor: "#70675b",
              }}
            >
              <ChallengeCard
                challenge={challenge}
                completeChallenge={completeChallenge}
                completed={false}
                loading={false}
                pendingCurses={props.pendingCurses.filter(
                  (curse) => curse.approvedByGM === false
                )}
              />
            </ChallengeCardStyled>
          ))}
          {props?.completedChallenges.map((challenge, index) => {
            console.log(challenge, props);
            if (challenge.approvedByGM === true) {
              return null;
            }
            return (
              <PendingChallengeCardStyled
                key={index + 1000}
                sx={{
                  marginBottom: "10px",
                  padding: "30px 30px 20px 30px",
                }}
              >
                <ChallengeCard
                  pending={true}
                  challenge={challenge}
                  completeChallenge={completeChallenge}
                  completed={true}
                />
              </PendingChallengeCardStyled>
            );
          })}
          {props?.completedChallenges.map((challenge, index) => {
            if (challenge.approvedByGM === false) {
              return null;
            }
            return (
              <CompletedChallengeCardStyled
                key={index + 1000}
                sx={{
                  marginBottom: "10px",
                  padding: "30px 30px 20px 30px",
                }}
              >
                <ChallengeCard
                  pending={false}
                  challenge={challenge}
                  completeChallenge={completeChallenge}
                  completed={true}
                />
              </CompletedChallengeCardStyled>
            );
          })}
        </div>
      )}
      <div className="footerBuffer" />
    </>
  );
};
export default Challenges;
