import React from "react";
import "../Pages.css"; // Create a CSS file for the animations
import unknownLBImage from "../../../assets/unknownLeaderboard.png";
import { Typography } from "@mui/material";
const UnknownLeaderboard = () => {
  return (
    <div className="gameOverScreen">
      <div className="crownImageContainer">
        <img
          className="crownImage"
          src={unknownLBImage}
          alt="unknown leaderboard"
        />
      </div>
      <br />
      <Typography variant="h4">Leaderboard Hidden</Typography>
    </div>
  );
};

export default UnknownLeaderboard;
