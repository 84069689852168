import React, { useState, useEffect, useCallback } from "react";
import "./CSS/CountdownTimer.css"; // Import your CSS file
import { Typography } from "@mui/material";

function CountdownTimer({ endTime, hideLeaderboardMins, isGameMaster }) {
  useEffect(() => {
    console.log("hideLeaderboardMins", hideLeaderboardMins);
  }, [hideLeaderboardMins]);

  const calculateTimeLeft = useCallback(() => {
    const endTimeDate = new Date(endTime);
    const now = new Date();

    if (isNaN(endTimeDate)) {
      console.error("Invalid date format", endTime);
      return {};
    }

    let difference = +endTimeDate - +now;
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  }, [endTime]);

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [timeLeftLeaderboard, setTimeLeftLeaderboard] = useState();

  useEffect(() => {
    const timerId = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    // Clear interval if the component is unmounted
    return () => clearInterval(timerId);
  }, [calculateTimeLeft]);

  useEffect(() => {
    const timerId = setInterval(() => {
      setTimeLeftLeaderboard(
        timeUntilBeforeEndtime(endTime, hideLeaderboardMins)
      );
    }, 1000);

    // Clear interval if the component is unmounted
    return () => clearInterval(timerId);
  }, [hideLeaderboardMins, endTime]);

  function timeUntilBeforeEndtime(endtime, minutesBeforeEndtime) {
    const endTimeDate = new Date(endtime);
    const targetTime = new Date(
      endTimeDate.getTime() - parseInt(minutesBeforeEndtime) * 60000
    );
    const now = new Date();

    const diff = targetTime - now;

    if (isGameMaster) {
      return false;
    }

    const hours = Math.floor(diff / 3600000);
    const minutes = Math.floor((diff % 3600000) / 60000);

    return `${hours} hours ${minutes} minutes`;
  }

  return (
    <>
      {!isGameMaster && (
        <Typography
          variant="subtitle1"
          sx={{ width: "100%", textAlign: "center" }}
        >
          Time Remaining
        </Typography>
      )}
      <div
        className={`timer ${
          (timeLeft.hours || 0) === 0 &&
          (timeLeft.minutes || 0) < hideLeaderboardMins
            ? "red-text"
            : "gray-text"
        }`}
      >
        <div className="time-section">
          <span className="time">{timeLeft.hours || "-"}</span>
          <span className="time-label">Hours</span>
        </div>
        <div className="time-section">
          <span className="time">{timeLeft.minutes || "-"}</span>
          <span className="time-label">Minutes</span>
        </div>
        <div className="time-section">
          <span className="time">{timeLeft.seconds || "-"}</span>
          <span className="time-label">Seconds</span>
        </div>
      </div>

      {timeLeftLeaderboard && (
        <Typography variant="subtitle2" sx={{ marginTop: "10px" }}>
          Hiding Leaderboard in {timeLeftLeaderboard}
        </Typography>
      )}
    </>
  );
}

export default CountdownTimer;
