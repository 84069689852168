import { Typography } from '@mui/material'
import React from 'react'
import { GameButton } from '../../MUI_Components/Components'

const ResultsPage = (props) => {
  return (
    <div style={{width: "100%", height: "100%", position: "absolute", left: 0, top: 0, backgroundColor: "white", zIndex: "100"}}>
        <div style={{height: "100%",display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
            <Typography sx={{color: "black"}}>This will have results... tbd (end game and go to previous games to find results)</Typography>
            <GameButton onClick={() => {props.setShowResults(false)}}>Back</GameButton>
        </div>
    </div>
  )
}

export default ResultsPage