import React from "react";
import {
  Slider,
  Typography,
  Box,
  Paper,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import styled from "@emotion/styled";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { timeInWords } from "../../../../utils/utils";
import { RealDivider } from "../../../MUI_Components/Components";

const ColorSlider = styled(Slider)(() => ({
  color: "#3F2E3E",
}));

function FlipClockDisplay({ value }) {
  // Formatting the value for flip clock display
  const hours = String(Math.floor(value / 60)).padStart(2, "0");
  const minutes = String(value % 60).padStart(2, "0");

  return (
    <Box sx={{ display: "flex", justifyContent: "center", marginBottom: 2 }}>
      <Paper
        sx={{
          padding: "10px",
          margin: "0 5px",
          fontSize: "2em",
          fontFamily: "Monospace",
          backgroundColor: "#000",
          color: "#fff",
        }}
      >
        {hours}
      </Paper>
      <Typography variant="h4" sx={{ alignSelf: "center" }}>
        :
      </Typography>
      <Paper
        sx={{
          padding: "10px",
          margin: "0 5px",
          fontSize: "2em",
          fontFamily: "Monospace",
          backgroundColor: "#000",
          color: "#fff",
        }}
      >
        {minutes}
      </Paper>
    </Box>
  );
}

function DurationPicker({
  hours,
  minutes,
  setHours,
  setMinutes,
  setHideMins,
  hideMins,
  packName,
  packList,
  handleChange,
}) {
  const totalMinutes = hours * 60 + minutes;

  return (
    <div>
      <Typography sx={{ textAlign: "center" }}>Game Duration</Typography>
      <FlipClockDisplay value={totalMinutes} />
      <Accordion sx={{ position: "inherit" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="settings-content"
          id="settings-header"
        >
          <Typography>Settings</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="h5" sx={{ marginBottom: "5px" }}>
            Game Length
          </Typography>
          <Typography>Hours: {hours}</Typography>
          <ColorSlider
            value={hours}
            onChange={(e, newHours) => setHours(newHours)}
            min={0}
            max={12}
            step={1}
            valueLabelDisplay="auto"
          />
          <Typography>Minutes: {minutes}</Typography>
          <ColorSlider
            value={minutes}
            onChange={(e, newMinutes) => setMinutes(newMinutes)}
            min={0}
            max={59}
            step={15}
            valueLabelDisplay="auto"
          />
          <RealDivider />
          <Typography variant="h5" sx={{ marginBottom: "5px" }}>
            Leaderboard Settings
          </Typography>
          {hideMins !== 0 && hideMins !== totalMinutes && (
            <Typography>{timeInWords(hideMins)} before game ends</Typography>
          )}
          {hideMins === 0 && <Typography>Never hide leaderboard</Typography>}
          {hideMins === totalMinutes && (
            <Typography>Always hide leaderboard</Typography>
          )}
          <ColorSlider
            value={hideMins}
            onChange={(e, newMinutes) => setHideMins(newMinutes)}
            min={0}
            max={totalMinutes}
            step={15}
          />
          <RealDivider />
          <Typography variant="h5" sx={{marginBottom: "5px"}}>Game Pack</Typography>
          {packName && packList?.length > 0 && (
            <div style={{ width: "100%", marginTop: "20px" }}>
              <FormControl fullWidth>
                <InputLabel>Pack</InputLabel>
                <Select value={packName} label="Pack" onChange={handleChange}>
                  {packList?.map(([value, label]) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
    // <Box>
    //   <RealDivider />

    //   <Typography sx={{ textAlign: "center" }}>Game Duration</Typography>
    //   <FlipClockDisplay value={totalMinutes} />
    //   <Typography>Hours: {hours}</Typography>
    //   <ColorSlider
    //     value={hours}
    //     onChange={(e, newHours) => setHours(newHours)}
    //     min={0}
    //     max={12}
    //     step={1}
    //     valueLabelDisplay="auto"
    //   />
    //   <Typography>Minutes: {minutes}</Typography>
    //   <ColorSlider
    //     value={minutes}
    //     onChange={(e, newMinutes) => setMinutes(newMinutes)}
    //     min={0}
    //     max={59}
    //     step={15}
    //     valueLabelDisplay="auto"
    //   />
    //   <RealDivider />
    //   {hideMins !== 0 ? (
    //     <Typography>
    //       Hide Leaderboard {hideMins} minutes before game ends
    //     </Typography>
    //   ) : (
    //     <Typography>Never hide leaderboard</Typography>
    //   )}
    //   <ColorSlider
    //     value={hideMins}
    //     onChange={(e, newMinutes) => setHideMins(newMinutes)}
    //     min={0}
    //     max={60}
    //     step={15}
    //     valueLabelDisplay="auto"
    //   />
    // </Box>
  );
}

export default DurationPicker;
