import React, { createContext, useContext, useState, useCallback } from 'react';

const ChatContext = createContext();

export const useChat = () => useContext(ChatContext);

export const ChatProvider = ({ children }) => {
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
  const [lastReadMessageId, setLastReadMessageId] = useState(null);

  const updateUnreadMessages = useCallback((status) => {
    console.log("setting chat status", status);
    setHasUnreadMessages(status);
  }, []);

  const updateLastReadMessageId = useCallback((id) => {
    setLastReadMessageId(id);
  }, []);

  return (
    <ChatContext.Provider 
      value={{
        hasUnreadMessages, 
        updateUnreadMessages,
        lastReadMessageId,
        updateLastReadMessageId
      }}>
      {children}
    </ChatContext.Provider>
  );
};
