import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";

const Loading = () => {
  return (
    <div sx={{ width: "100%", height: "100%", backgroundColor: "red" }}>
      <CircularProgress />;
    </div>
  );
};

export default Loading;
