import React, { useState } from "react";
import Confetti from "react-confetti";
import "./Pages.css";
import { styled } from "@mui/system";
import CircularProgress from "@mui/material/CircularProgress";

// Import your wrinkled paper image
import curseCardBackgroundNew from "../../assets/cursed.svg";
import { Alert, Snackbar, Typography } from "@mui/material";
import CurseCard from "./CurseCard.js";

const getRandomInt = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const CurseCardDiv = styled("div")({
  background: `url(${curseCardBackgroundNew})`,
  color: "white",
  backgroundSize: "cover",
  borderRadius: "8px",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
  transform: (props) =>
    `rotate(${getRandomInt(-5, 5)}deg) scale(${getRandomInt(95, 105) / 100})`,
});

const Cursed = (props) => {
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [showConfetti, setShowConfetti] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState([
    "Finding Challenges",
    "Configuring Gamemode",
    "Giving Charlie the worst ones",
    "Making Calum Express emotion",
    "Finalizing",
    "Finalizing.",
    "Finalizing..",
    "Finalizing...",
  ]);

  async function isVetoResAnError(res, curseId) {
    console.log("res", res);
    if (res === "Insufficient coins to veto.") {
      setAlertMessage("Not Enough Coins");
      setAlertSeverity("error");
    } else {
      setAlertMessage("Successfully Purchased");
      setAlertSeverity("success");
    }
    return res;
  }

  function completeCurse(curseId) {
    console.log(curseId, props.uid, props.user.game);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      gameId: props.user.game,
      playerId: props.uid,
      curseId: curseId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://completecurse-nykygdtd7a-uc.a.run.app", requestOptions)
      .then((response) => response.text())
      .then((result) => isResAnError(result))
      .catch((error) => console.log("error", error));
  }

  function vetoCurse(curseId, uniqueCurseId) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("gameId", props.user.game);
    urlencoded.append("playerId", props.uid);
    urlencoded.append("curseId", curseId);
    urlencoded.append("uniqueCurseId", uniqueCurseId);
    urlencoded.append("pack", props.game.host.pack);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    fetch("https://vetocurse-nykygdtd7a-uc.a.run.app", requestOptions)
      .then((response) => response.text())
      .then((result) => isVetoResAnError(result, curseId))
      .catch((error) => console.log("error", error));
    setOpen(true);
  }

  function isResAnError(res) {
    if (res === "Challenge curse successfully.") {
      showAndHideConfetti();
    }
  }

  function delay(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  async function showAndHideConfetti() {
    setShowConfetti(true);
    delay(3000).then(() => setShowConfetti(false));
  }

  return (
    <>
      <>
        <>
          {showConfetti && (
            <Confetti
              sx={{
                opacity: 1,
                transition: "opacity 0.5s",
                width: "100vw",
                height: "70vh",
                position: "fixed",
                zIndex: "23",
              }}
              onAnimationEnd={() => console.log("completed confetti")}
            />
          )}
          {props.curses.length === 0 ? (
            <div className="challengesLoading">
              <CircularProgress />
              <br />
              <Typography variant="h6">{loadingMessage[0]}</Typography>
            </div>
          ) : (
            <h1>
              {props.curses.map((curse) => {
                return (
                  <CurseCardDiv
                    sx={{
                      marginBottom: "10px",
                      padding: "30px 30px 20px 30px",
                    }}
                  >
                    <CurseCard
                      curse={curse}
                      completeCurse={completeCurse}
                      completed={false}
                      loading={false}
                      vetoCurse={vetoCurse}
                    />
                  </CurseCardDiv>
                );
              })}
            </h1>
          )}
        </>
        {/* )} */}
        <div className="footerBuffer" />
      </>

      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        sx={{ marginBottom: "60px" }}
      >
        <Alert
          onClose={handleClose}
          severity={alertSeverity}
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Cursed;
