import React, { useState } from "react";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import {
  GameButton,
  RoundedCard,
  RoundedTextField,
} from "../MUI_Components/Components";
import logo from "../../assets/2024logo.png";
import "./Home.css";
import {
  Checkbox,
  FormControlLabel,
  Tooltip,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tabs,
  Tab,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getDatabase, ref, set, get, child } from "firebase/database"; // Import Firebase functions

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default function Home() {
  const [value, setValue] = useState(0);
  const [registered, setRegistered] = useState(false);
  const [participant, setParticipant] = useState(false);
  const [volunteerGameMaster, setVolunteerGameMaster] = useState(false);
  const [error, setError] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  async function handleSubmit(e) {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const email = data.get("email");
    const fullname = data.get("fullname");

    if (email === "") {
      setError("You must enter an email address");
      return;
    }
    if (fullname === "") {
      setError("You must enter your name");
      return;
    }
    if (!participant && !volunteerGameMaster) {
      setError("You must choose either participant or game master");
      return;
    }
    setError(false);

    const db = getDatabase();
    const dbRef = ref(db);

    try {
      const snapshot = await get(child(dbRef, "registered"));
      if (snapshot.exists()) {
        const registeredUsers = snapshot.val();
        const emailExists = Object.values(registeredUsers).some(
          (user) => user.email === email
        );

        if (emailExists) {
          setError("This email is already registered");
          return;
        }
      }

      const newUserRef = ref(
        db,
        "registered/" + email.replace(/[.#$[\]]/g, "")
      );
      await set(newUserRef, {
        email: email,
        fullname: fullname,
        participant: participant,
        volunteerGameMaster: volunteerGameMaster,
      });

      console.log("Data submitted successfully");
      setRegistered(true);
    } catch (error) {
      console.error("Error submitting data: ", error);
      setError("Error submitting data");
    }
  }

  return (
    <RoundedCard>
      <Container component="main" maxWidth="md">
        <Box
          sx={{
            marginTop: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div>
            <img
              src={logo}
              alt="mediocre race logo"
              style={{ width: "200px", animation: "rock 2s infinite" }}
            />
          </div>
          <Typography variant="h4" sx={{ mt: 3, textAlign: "center" }}>
            Welcome to the Mediocre Race 2024
          </Typography>
          <Typography variant="subtitle1" sx={{ mt: 1, textAlign: "center" }}>
            September 21<sup>st</sup> 2024
          </Typography>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Register" />
            <Tab label="FAQ" />
          </Tabs>
          <TabPanel value={value} index={0}>
            {!registered ? (
              <>
                <Typography variant="body1" sx={{ mt: 2, textAlign: "center" }}>
                  Enter your email below to register your interest in this
                  year’s race and receive updates! This is not the official
                  signup, so don’t worry if you change your mind.
                </Typography>
                <Box
                  component="form"
                  onSubmit={handleSubmit}
                  noValidate
                  sx={{ mt: 3 }}
                >
                  {error && (
                    <Typography sx={{ color: "red", textAlign: "center" }}>
                      {error}
                    </Typography>
                  )}
                  <RoundedTextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                  />
                  <RoundedTextField
                    margin="normal"
                    required
                    fullWidth
                    id="fullname"
                    label="Full Name"
                    name="fullname"
                    autoComplete="name"
                  />
                  <Grid
                    container
                    sx={{
                      marginTop: "20px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Tooltip title="Select if you want to participate in the event">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={participant}
                            onChange={(e) => setParticipant(e.target.checked)}
                            name="participant"
                            color="primary"
                          />
                        }
                        label="Participant"
                      />
                    </Tooltip>
                    <Tooltip title="Select if you want to volunteer as a game master">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={volunteerGameMaster}
                            onChange={(e) =>
                              setVolunteerGameMaster(e.target.checked)
                            }
                            name="volunteerGameMaster"
                            color="primary"
                          />
                        }
                        label="Volunteer Game Master"
                      />
                    </Tooltip>
                  </Grid>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <GameButton type="submit" fullWidth variant="contained">
                      Register Interest!
                    </GameButton>
                  </div>

                  <Grid
                    container
                    sx={{
                      marginTop: "20px",
                      display: "flex",
                      justifyContent: "right",
                    }}
                  >
                    <Link href="/login" variant="body2">
                      {"Home"}
                    </Link>
                  </Grid>
                </Box>
              </>
            ) : (
              <Box sx={{ mt: 3, textAlign: "center" }}>
                <Typography variant="h4" sx={{ color: "green" }}>
                  You have been registered! Thank you
                </Typography>
              </Box>
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Box sx={{ mt: 5 }}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    What is the Mediocre Race?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    The Mediocre Race is an Ottawa-based game created by Craig
                    and Tricia. Using themediocrerace.com, each team of 2
                    chooses to complete challenges that are randomly drawn from
                    a virtual deck. Completing a challenge awards the team with
                    a set amount of coins and/or jewels, and the team with the
                    most coins at the end of the race wins. Coins and jewels can
                    also be used to purchase advantages or curses throughout the
                    day.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    When is the 2024 race?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    The race will be held on September 21st, 2024 from 10am -
                    4pm. The after party will commence around 6pm.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    What kind of challenges will there be?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    There are over 100 different challenges that range in
                    difficulty and coin value. At any given time, teams will
                    have up to 5 to choose from. Generally, the challenge will
                    be to find something, create something, or travel somewhere.
                    Some of the examples from last year include:
                    <ul>
                      <li>Find a real Scottish flag</li>
                      <li>Build an Inukshuk from anything</li>
                      <li>Paddle a canoe from Dow’s Lake to Heart Island</li>
                    </ul>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    What are the rules of the race?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <ol>
                      <li>
                        Public transportation and walking are the only modes of
                        transportation allowed (unless otherwise specified in a
                        challenge). This means no cars, bikes, scooters, etc.
                        can be used for the entire duration.
                      </li>
                      <li>
                        Sufficient evidence (pictures and/or videos) must be
                        submitted in order to receive the coins for each
                        challenge completed. The Game Council may request
                        additional evidence if the initial evidence is not
                        satisfactory.
                      </li>
                      <li>
                        Team members must remain together for the duration of
                        the race. Any challenges completed individually will not
                        be accepted.
                      </li>
                      <li>
                        Items displayed on a screen do not count as “found.”
                      </li>
                      <li>
                        You cannot interfere with another team’s attempt at a
                        challenge. Curses, however, are encouraged!
                      </li>
                      <li>
                        Finally, the main goal is to have fun and do your best
                        to satisfy the spirit of the challenge. If you are ever
                        unsure, consult with the Game Council.
                      </li>
                    </ol>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    Is there a cost?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    This year, we are asking for each participant to donate a
                    minimum of $30 to cover the costs of the event (prizes,
                    t-shirts, supplies, after party). Any excess funds will be
                    donated to the Children’s Hospital of Eastern Ontario.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    Who is the Game Council?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    The Game Council is our small team of volunteers who will
                    spend the day verifying challenge evidence and answering any
                    questions you may have. While only one individual will be
                    verifying each piece of evidence, any uncertainties or
                    disputes will be put to a vote with the others. This is a
                    demanding role, so be sure to thank them at the after party!
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    What is the after party? Do I have to go?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    The after party will be a gathering of all participants and
                    volunteers at Craig and Tricia’s house to announce the
                    winners. We will have food (provided), drinks, and go over
                    some highlights of the day! Your attendance is optional but
                    highly encouraged.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    What are the prizes?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    While we would like to keep the prizes discrete for now, we
                    are confident you will love them. Last year’s grand prize
                    was two day passes to the Nordic Spa, while second and third
                    place received various gift cards.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
          </TabPanel>
        </Box>
      </Container>
    </RoundedCard>
  );
}
