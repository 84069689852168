import React, { useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import Avatar from "@mui/material/Avatar";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Alert from "@mui/material/Alert";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {
  GameButton,
  RoundedCard,
  RoundedTextField,
} from "../MUI_Components/Components";


const ForgotPassword = () => {
  const { resetPassword } = useAuth();
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();
    const data = new FormData(e.currentTarget);

    try {
      setError("");
      setLoading(true);
      await resetPassword(data.get("email"));
      setMessage("Check your inbox for further instructions");
    } catch {
      setError("Failed to reset password");
    }

    setLoading(false);
  }

  return (
    <RoundedCard>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <RestartAltIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Reset Password
          </Typography>
          {error && <Alert severity="error">{error}</Alert>}
          {message && <Alert severity="success">{message}</Alert>}
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
          >
            <RoundedTextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
              <GameButton
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={loading}
              >
                Request Password Reset Email
              </GameButton>
            </div>

            <Grid container sx={{ textAlign: "center" }}>
              <Grid item xs>
                <Link href="/Login" variant="body2">
                  Cancel
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </RoundedCard>
  );
};

export default ForgotPassword;
