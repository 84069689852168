import React, { useState } from "react";
import {
  GameButton,
  RealDivider,
  RoundedTextField,
} from "../../../MUI_Components/Components";
import { JoinFull, JoinInner, Draw, History } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { isGameStarted, joinGame } from "../../../../firebase";
import { useAuth } from "../../../../contexts/AuthContext";
import HowToRegIcon from "@mui/icons-material/HowToReg";

const NewGame = (props) => {
  const navigate = useNavigate();
  const [enteredCode, setEnteredCode] = useState("");
  const { currentUser } = useAuth();

  return (
    <>
      <RoundedTextField
        placeholder="Game Code"
        fullWidth
        onChange={(e) => setEnteredCode(e.target.value)}
      />
      <GameButton
        fullWidth
        variant="contained"
        startIcon={<JoinFull />}
        onClick={() => {
          let code = enteredCode.toUpperCase();
          isGameStarted(code).then((res) => {
            console.log("res", res);
            if (res === false) {
              props.setError("No Game With That Code Exists");
            } else if (res === "started") {
              props.setError("That Game has already started");
            } else {
              console.log("code", code);
              props.setError("");
              joinGame(currentUser.uid, props.user.name, code);
            }
          });
        }}
      >
        Join Game
      </GameButton>

      <GameButton
        fullWidth
        variant="contained"
        startIcon={<JoinInner />}
        onClick={() => {
          navigate("/game-master");
        }}
      >
        Join Game As GM
      </GameButton>
      <RealDivider />
      <GameButton
        fullWidth
        variant="contained"
        onClick={props.handleCreateGame}
        startIcon={<Draw />}
      >
        Create Game
      </GameButton>
      <GameButton
        fullWidth
        variant="contained"
        onClick={() => {
          navigate("/previous-games");
        }}
        startIcon={<History />}
      >
        Previous Games
      </GameButton>
      {props?.role === "admin" && (
        <GameButton
          fullWidth
          variant="contained"
          onClick={() => {
            navigate("/registered");
          }}
          startIcon={<HowToRegIcon />}
        >
          Registered People
        </GameButton>
      )}
    </>
  );
};

export default NewGame;
