import React, { useEffect, useState } from 'react';
import { useChat } from '../../contexts/ChatContext';
import { onValue, ref } from 'firebase/database';
import { getDB } from '../../firebase';

const ChatListener = ({ user, page }) => {
  const { updateUnreadMessages } = useChat();
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  useEffect(() => {
    // Retrieve the last read message ID from local storage
    const storedLastReadMessageId = localStorage.getItem('lastReadMessageId');
    let lastReadMessageId = storedLastReadMessageId ? parseInt(storedLastReadMessageId, 10) : 0;

    if (page !== 3 && user.game !== "false") {
      const gameQuery = ref(getDB(), "/games/" + user.game + "/messages");
      const unsubscribe = onValue(gameQuery, (snapshot) => {
        if (snapshot.exists() && snapshot.val() !== null) {
          const messages = snapshot.val();
          console.log("messages", messages)
          console.log("lastRead", lastReadMessageId)
          const newMessages = Object.values(messages).filter(
            message => message.timestamp > lastReadMessageId
          );
          console.log("newMessages", newMessages)

          // Update the condition to check if it's not the initial load
          if (!isInitialLoad && newMessages.length > 0) {
            updateUnreadMessages(true);
          }

          
          setIsInitialLoad(false);
        }
      });

      return () => unsubscribe();
    }
  }, [page, user.game, updateUnreadMessages, isInitialLoad]);

  return <></>;
};

export default ChatListener;
