import React, { useEffect, useState, useCallback, memo } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Box,
  Paper,
  Avatar,
  Drawer,
  List,
  ListItemText,
  ListItemAvatar,
  Divider,
  IconButton,
  ListItemButton,
  Checkbox,
  Grid,
  ListItem,
  Badge,
} from "@mui/material";
import { onValue, ref, set } from "firebase/database";
import { getDB } from "../../firebase";
import { useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import "./ControlGame.css";
import ControlGameUserTab from "./ControlGameUserTab";
import CountdownTimer from "../DashboardPages/ProfilePage/Components/CountdownTimer";
import { useAuth } from "../../contexts/AuthContext";
import { color } from "framer-motion";
import LeaderboardLineItem from "../DashboardPages/Leaderboard/LeaderboardLineItem";
import GMLeaderboardLineItem from "../DashboardPages/ProfilePage/GMProfile/GMLeaderboardLineItem";

// Helper Functions
const convertObjectToArrayWithId = (obj) =>
  obj ? Object.keys(obj).map((key) => ({ ...obj[key], uid: key })) : [];

const CustomTabPanel = memo(({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`tabpanel-${index}`}
    className="tabpanel"
    aria-labelledby={`tab-${index}`}
    {...other}
  >
    {value === index && <Box>{children}</Box>}
  </div>
));

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const drawerWidth = 200; // Width for the sidebar

const ControlGame = (props) => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const [game, setGame] = useState(null);
  const [players, setPlayers] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [userDataMap, setUserDataMap] = useState({});
  const [selectedPlayers, setSelectedPlayers] = useState({});
  const [selectedPlayersLoaded, setSelectedPlayersLoaded] = useState(false); // New state variable
  const gameId = props.game;
  const { currentUser } = useAuth();
  // Inside your ControlGame component
  const [statsData, setStatsData] = useState({});

  // Fetch stats data
  useEffect(() => {
    const db = getDB();
    const statsRef = ref(db, `/games/${gameId}/stats`);

    const unsubscribe = onValue(statsRef, (snapshot) => {
      const data = snapshot.val() || {};
      setStatsData(data);
    });

    return () => {
      unsubscribe();
    };
  }, [gameId]);
  const gameMasterUid = currentUser.uid;

  // Handlers
  const handleChange = useCallback((newValue) => {
    setValue(newValue);
  }, []);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  // Fetch game and player data
  useEffect(() => {
    const db = getDB();
    const gameRef = ref(db, `/games/${gameId}`);

    const gameUnsubscribe = onValue(gameRef, (snapshot) => {
      const data = snapshot.val();
      if (snapshot.exists()) {
        setGame(data);
        const playerArray = convertObjectToArrayWithId(data.players || {});
        setPlayers(playerArray);

        // Fetch user data for each player
        playerArray.forEach((player) => {
          const userRef = ref(db, `/users/${player.uid}`);
          onValue(userRef, (userSnapshot) => {
            const userData = userSnapshot.val() || {};
            setUserDataMap((prev) => ({
              ...prev,
              [player.uid]: userData,
            }));
          });
        });
      }
    });

    // Cleanup function
    return () => {
      gameUnsubscribe();
      // Cleanup user listeners if needed
    };
  }, [gameId]);

  // Fetch selectedPlayers from database
  useEffect(() => {
    const db = getDB();
    const gmRequestsRef = ref(
      db,
      `/games/${gameId}/gameMasterRequests/${gameMasterUid}/selectedplayers/`
    );

    const unsubscribe = onValue(gmRequestsRef, (selectedPlayersSnapshot) => {
      const selectedPlayersData = selectedPlayersSnapshot.val();
      if (selectedPlayersData) {
        setSelectedPlayers(selectedPlayersData);
      } else {
        setSelectedPlayers({});
      }
      setSelectedPlayersLoaded(true); // Indicate that loading is complete
    });

    return () => {
      unsubscribe();
    };
  }, [gameId, gameMasterUid]);

  // Update selectedPlayers when new players join
  useEffect(() => {
    if (selectedPlayersLoaded) {
      setSelectedPlayers((prevSelectedPlayers) => {
        const newSelectedPlayers = { ...prevSelectedPlayers };
        players.forEach((player) => {
          if (!(player.uid in newSelectedPlayers)) {
            newSelectedPlayers[player.uid] = true; // Default to true for new players
          }
        });
        return newSelectedPlayers;
      });
    }
  }, [players, selectedPlayersLoaded]);

  // Update gameMasterRequests in the database when selectedPlayers change
  useEffect(() => {
    if (selectedPlayersLoaded) {
      const db = getDB();
      const gmRequestsRef = ref(
        db,
        `/games/${gameId}/gameMasterRequests/${gameMasterUid}/selectedplayers/`
      );
      set(gmRequestsRef, selectedPlayers);
    }
  }, [selectedPlayers, gameId, gameMasterUid, selectedPlayersLoaded]);

  // Handle checkbox changes
  const handleCheckboxChange = (playerUid) => {
    setSelectedPlayers((prev) => ({
      ...prev,
      [playerUid]: !prev[playerUid],
    }));
  };

  // Compute leaderboard data
  const leaderboardData = players.map((player) => {
    const userData = userDataMap[player.uid] || {};
    const username = userData.username || player.username || "";
    const coins = player.coins || 0;
    const gems = player.gems || 0;
    const challengesCompleted = player.completedChallenges
      ? Object.keys(player.completedChallenges).length
      : 0;
    return {
      uid: player.uid,
      username,
      coins,
      gems,
      challengesCompleted,
      avatar: userData.image || "",
    };
  });

  console.log("leaderboardData", leaderboardData);

  // Sort leaderboard by coins (descending)
  leaderboardData.sort((a, b) => b.coins - a.coins);

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "50px",
          backgroundColor: "rgba(255, 255, 255, 0.1)",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          backdropFilter: "blur(10px)",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" sx={{ color: "white" }}>
          Game Master Console
        </Typography>
      </div>
      {game?.host?.started === "ended" && (
        <div
          style={{
            width: "100%",
            height: "30px",
            backgroundColor: "red",
            position: "relative",
            display: "flex",
            justifyContent: "center",
            backdropFilter: "blur(10px)",
            alignItems: "center",
            zIndex: 100,
          }}
        >
          <Typography variant="h5" sx={{ color: "white" }}>
            GAME ENDED
          </Typography>
        </div>
      )}
      {/* Adjusted root container */}
      <Box sx={{ display: "flex", width: "100%", overflowX: "hidden" }}>
        <Drawer
          variant="permanent"
          open={drawerOpen}
          sx={{
            width: drawerOpen ? drawerWidth : `56px`,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerOpen ? drawerWidth : `56px`,
              boxSizing: "border-box",
              overflowX: "hidden",
              backgroundColor: "rgba(255, 255, 255, 0.1)",
              backdropFilter: "blur(10px)",
            },
          }}
        >
          {/* Drawer content */}
          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <List>
              <ListItemButton
                selected={value === 0}
                onClick={() => handleChange(0)}
                sx={{ justifyContent: drawerOpen ? "initial" : "center" }}
              >
                <ListItemAvatar
                  sx={{ minWidth: 0, mr: drawerOpen ? 2 : "auto" }}
                >
                  <HomeIcon sx={{ color: "white" }} />
                </ListItemAvatar>
                {drawerOpen && (
                  <ListItemText primary="Home" sx={{ color: "white" }} />
                )}
              </ListItemButton>
              <ListItemButton
                selected={value === 1}
                onClick={() => handleChange(1)}
                sx={{ justifyContent: drawerOpen ? "initial" : "center" }}
              >
                <ListItemAvatar
                  sx={{ minWidth: 0, mr: drawerOpen ? 2 : "auto" }}
                >
                  <EqualizerIcon sx={{ color: "white" }} />
                </ListItemAvatar>
                {drawerOpen && (
                  <ListItemText primary="Stats" sx={{ color: "white" }} />
                )}
              </ListItemButton>
              <ListItemButton
                onClick={() => navigate("/")}
                sx={{ justifyContent: drawerOpen ? "initial" : "center" }}
              >
                <ListItemAvatar
                  sx={{ minWidth: 0, mr: drawerOpen ? 2 : "auto" }}
                >
                  <AccessibilityNewIcon sx={{ color: "white" }} />
                </ListItemAvatar>
                {drawerOpen && (
                  <ListItemText primary="Player UI" sx={{ color: "white" }} />
                )}
              </ListItemButton>
              <Divider sx={{ my: 1 }} />

              {/* Team List */}
              {players.map((player, index) => {
                console.log("player", player);
                const userData = userDataMap[player.uid] || {};
                const isSelected = selectedPlayers[player.uid];
                if (!isSelected) {
                  return null;
                }
                return (
                  <ListItemButton
                    selected={isSelected ? value === index + 2 : false}
                    onClick={() => handleChange(index + 2)}
                    key={player.uid}
                    sx={{
                      justifyContent: drawerOpen ? "initial" : "center",
                      opacity: isSelected ? 1 : 0.5,
                    }}
                  >
                    <ListItemAvatar
                      sx={{ minWidth: 0, mr: drawerOpen ? 2 : "auto" }}
                    >
                      <Badge
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        badgeContent={
                          Object.values(
                            player?.completedChallenges || {}
                          ).filter(
                            (challenge) => challenge?.approvedByGM === false
                          ).length
                        }
                        color="error"
                      >
                        <Badge
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          badgeContent={
                            Object.values(player?.completedCurses || {}).filter(
                              (curse) => !curse.approvedByGM
                            ).length
                          }
                          color="secondary"
                        >
                          <Avatar
                            src={userData.image || ""}
                            alt={userData.username || player.username || ""}
                            sx={{ color: "white" }}
                          >
                            {userData.username
                              ? userData.username.charAt(0)
                              : player.username
                              ? player.username.charAt(0)
                              : ""}
                          </Avatar>
                        </Badge>
                      </Badge>
                    </ListItemAvatar>
                    {drawerOpen && (
                      <ListItemText
                        primary={userData.username || player.username || ""}
                        sx={{ whiteSpace: "nowrap", color: "white" }}
                      />
                    )}
                  </ListItemButton>
                );
              })}
            </List>
            <IconButton
              onClick={handleDrawerToggle}
              sx={{
                alignSelf: "flex-end",
                mb: 1,
                mr: 1,
                color: "white",
              }}
            >
              {drawerOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </Box>
        </Drawer>

        {/* Main content area adjusted */}
        <Box
          sx={{
            marginTop: "10px",
            flexGrow: 1,
            overflow: "auto",
          }}
        >
          <Paper
            sx={{
              backgroundColor: "transparent",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {game && (
              <Box sx={{ width: "90%" }}>
                {/* Render content based on value */}
                {value === 0 && (
                  <CustomTabPanel value={value} index={0}>
                    {/* Home Tab Content */}
                    <Box
                      sx={{
                        mt: 4,
                        p: 3,
                        backgroundColor: "rgba(255, 255, 255, 0.7)",
                        borderRadius: 2,
                      }}
                    >
                      <Typography
                        variant="h5"
                        sx={{ mb: 2, textAlign: "center", color: "black" }}
                      >
                        Select Teams to View
                      </Typography>
                      <Grid container spacing={2}>
                        {players.map((player) => {
                          const userData = userDataMap[player.uid] || {};
                          const isSelected =
                            selectedPlayers[player.uid] ?? true;

                          return (
                            <Grid item xs={12} md={4} key={player.uid}>
                              <Paper
                                elevation={3}
                                sx={{
                                  p: 2,
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Avatar
                                  src={userData.image || ""}
                                  alt={
                                    userData.username || player.username || ""
                                  }
                                  sx={{ mr: 2 }}
                                >
                                  {userData.username
                                    ? userData.username.charAt(0)
                                    : player.username
                                    ? player.username.charAt(0)
                                    : ""}
                                </Avatar>
                                <ListItemText
                                  primary={
                                    userData.username || player.username || ""
                                  }
                                />
                                <Checkbox
                                  checked={isSelected}
                                  onChange={() =>
                                    handleCheckboxChange(player.uid)
                                  }
                                />
                              </Paper>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Box>
                  </CustomTabPanel>
                )}
                {value === 1 && (
                  <CustomTabPanel value={value} index={1}>
                    {/* Stats Tab Content */}
                    {game.host?.endTime && (
                      <div style={{ padding: "2rem", paddingTop: "50px" }}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={4}>
                            <Paper
                              elevation={3}
                              sx={{
                                padding: 2,
                                textAlign: "center",
                                backgroundColor: "rgba(255, 255, 255, 0.7)",
                              }}
                            >
                              <Typography variant="h6">
                                Time Remaining
                              </Typography>
                              <CountdownTimer
                                endTime={game.host.endTime}
                                hideLeaderboardMins={
                                  game?.host?.hideLeaderboardMins
                                }
                                isGameMaster={true}
                              />
                            </Paper>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <Paper
                              elevation={3}
                              sx={{
                                padding: 2,
                                textAlign: "center",
                                backgroundColor: "rgba(255, 255, 255, 0.7)",
                              }}
                            >
                              <Typography variant="h6">Total Teams</Typography>
                              <Typography variant="h4">
                                {players.length}
                              </Typography>
                            </Paper>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <Paper
                              elevation={3}
                              sx={{
                                padding: 2,
                                textAlign: "center",
                                backgroundColor: "rgba(255, 255, 255, 0.7)",
                              }}
                            >
                              <Typography variant="h6">My Teams</Typography>
                              <Typography variant="h4">
                                {
                                  players.filter(
                                    (player) => selectedPlayers[player.uid]
                                  ).length
                                }
                              </Typography>
                            </Paper>
                          </Grid>
                        </Grid>
                      </div>
                    )}

                    {/* Leaderboard Section */}
                    <Box sx={{ mt: 4, p: 3 }}>
                      <Typography
                        variant="h5"
                        sx={{ mb: 2, textAlign: "center" }}
                      >
                        Leaderboard
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        {leaderboardData.length > 0 && (
                          <>
                            {leaderboardData[0] && (
                              <GMLeaderboardLineItem
                                userData={{
                                  image: leaderboardData[0].avatar,
                                  name: leaderboardData[0].username,
                                  username: leaderboardData[0].username,
                                  coins: leaderboardData[0].coins,
                                  gems: leaderboardData[0].gems, // Add gems here
                                }}
                                gradientId={`firstPlaceGradient`}
                                avatarSize={"90px"}
                                iconSize={"60px"}
                                mainColor={"#f1b84a"}
                                secondaryColor={"#cf7108"}
                                width={"95%"}
                                height={"80px"}
                              />
                            )}
                            {leaderboardData.length > 1 && (
                              <GMLeaderboardLineItem
                                userData={{
                                  image: leaderboardData[1].avatar,
                                  name: leaderboardData[1].username,
                                  username: leaderboardData[1].username,
                                  coins: leaderboardData[1].coins,
                                  gems: leaderboardData[1].gems, // Add gems here
                                }}
                                gradientId={`secondPlaceGradient`}
                                avatarSize={"80px"}
                                iconSize={"40px"}
                                mainColor={"#E1E1E1"}
                                secondaryColor={"#AFAFAF"}
                                width={"90%"}
                                height={"70px"}
                              />
                            )}
                            {leaderboardData.length > 2 && (
                              <GMLeaderboardLineItem
                                userData={{
                                  image: leaderboardData[2].avatar,
                                  name: leaderboardData[2].username,
                                  username: leaderboardData[2].username,
                                  coins: leaderboardData[2].coins,
                                  gems: leaderboardData[2].gems, // Add gems here
                                }}
                                gradientId={`thirdPlaceGradient`}
                                avatarSize={"80px"}
                                iconSize={"40px"}
                                mainColor={"#CD7F32"}
                                secondaryColor={"#e6b899"}
                                width={"90%"}
                                height={"70px"}
                              />
                            )}
                            {leaderboardData.length > 3 &&
                              leaderboardData.slice(3).map((team, index) => (
                                <GMLeaderboardLineItem
                                  userData={{
                                    image: team.avatar,
                                    name: team.username,
                                    username: team.username,
                                    coins: team.coins,
                                    gems: team.gems, // Add gems here
                                  }}
                                  avatarSize={"30px"}
                                  iconSize={"40px"}
                                  mainColor={"#808080"}
                                  secondaryColor={"#808080"}
                                  width={"90%"}
                                  height={"60px"}
                                  key={team.uid}
                                  rank={index + 4}
                                />
                              ))}
                          </>
                        )}
                        {leaderboardData.length === 0 && (
                          <Typography>
                            No players have made the leaderboard yet
                          </Typography>
                        )}
                      </div>
                    </Box>

                    {/* Stats Section */}
                    <Box sx={{ mt: 4, p: 3, color: "white" }}>
                      <Typography
                        variant="h5"
                        sx={{ mb: 2, textAlign: "center" }}
                      >
                        Curses Stats
                      </Typography>

                      <List>
                        {Object.entries(statsData).map(([uid, stats]) => {
                          const username =
                            players.find((user) => user.uid === uid)
                              ?.username || "Unknown User";
                          const hasCursed = stats.HasCursed || 0;
                          const hasBeenCursed = stats.HasBeenCursed || 0;

                          // Filter out "HasCursed" and "HasBeenCursed" to show other curse stats
                          const otherCurses = Object.entries(stats).filter(
                            ([key]) =>
                              key !== "HasCursed" &&
                              key !== "HasBeenCursed" &&
                              key !== "CompletedCurse"
                          );

                          return (
                            <div
                              key={uid}
                              style={{ color: "white", marginBottom: "20px" }}
                            >
                              <Typography variant="subtitle1">{`${username}:`}</Typography>
                              <Typography variant="subtitle2">{`Has Cursed: ${hasCursed}, Has Been Cursed: ${hasBeenCursed}`}</Typography>

                              {otherCurses.length > 0 && (
                                <Typography variant="subtitle2">
                                  {otherCurses.map(([curse, count]) => (
                                    <span
                                      key={curse}
                                    >{`${curse}: ${count}, `}</span>
                                  ))}
                                </Typography>
                              )}
                            </div>
                          );
                        })}
                      </List>
                    </Box>
                  </CustomTabPanel>
                )}
                {value >= 2 && (
                  <CustomTabPanel value={value} index={value}>
                    {/* Team Content */}
                    {(() => {
                      const selectedTeams = players.filter(
                        (player) => selectedPlayers[player.uid]
                      );
                      const selectedTeamIndex = value - 2;
                      const teamPlayer = selectedTeams[selectedTeamIndex];
                      if (teamPlayer) {
                        return (
                          <ControlGameUserTab
                            game={game}
                            player={teamPlayer}
                            code={gameId}
                          />
                        );
                      } else {
                        return <Typography>No team data available.</Typography>;
                      }
                    })()}
                  </CustomTabPanel>
                )}
              </Box>
            )}

            {game && game?.host?.started === "false" && (
              <Box sx={{ p: 3, color: "white" }}>
                <Typography variant="h5" align="center">
                  The game is waiting to be started by the host.
                </Typography>
                <Typography variant="h6" align="center">
                  Teams who have joined:
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    mt: 2,
                  }}
                >
                  {players.length > 0 ? (
                    players.map((player, index) => {
                      const userData = userDataMap[player.uid] || {};
                      return (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "10px",
                          }}
                        >
                          <Avatar
                            src={userData.image || ""}
                            alt={userData.username || player.username || ""}
                            sx={{ marginRight: "10px" }}
                          >
                            {userData.username
                              ? userData.username.charAt(0)
                              : player.username
                              ? player.username.charAt(0)
                              : ""}
                          </Avatar>
                          <Typography>
                            {userData.username || player.username}
                          </Typography>
                        </div>
                      );
                    })
                  ) : (
                    <Typography>No teams have joined yet.</Typography>
                  )}
                </Box>
              </Box>
            )}
          </Paper>
        </Box>
      </Box>
    </>
  );
};

export default ControlGame;
