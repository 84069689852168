import React from "react";
import { Box, Paper, Typography } from "@mui/material";
import { formatDistanceToNow } from "date-fns";

const MessageBubble = ({
  message,
  timestamp,
  username,
  isPlayer,
  isNewMessage,
}) => {
  const timeAgo = (timestamp) => {
    return formatDistanceToNow(new Date(timestamp), { addSuffix: false });
  };

  return (
    <div>
      <Typography
        variant="caption"
        sx={{
          display: "block",
          marginTop: "8px",
          marginRight: isPlayer ? "20px" : 0,
          textAlign: isPlayer ? "right" : "left",
          marginLeft: !isPlayer ? "20px" : 0,
        }}
      >
        {isPlayer ? "You" : username} • {timeAgo(timestamp)}
      </Typography>
      <div style={{ width: "100%" }}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems={isPlayer ? "flex-end" : "flex-start"}
        >
          <Paper
            sx={{
              padding: "10px 15px",
              marginRight: isPlayer ? "20px" : 0,
              marginLeft: !isPlayer ? "20px" : 0,
              marginBottom: "10px",
              borderRadius: "20px",
              backgroundColor: isPlayer ? "#E8F0FE" : "lightgrey",
              color: "#000",
              maxWidth: "fit-content",
              alignSelf: isPlayer ? "flex-end" : "flex-start",
            }}
            elevation={3}
          >
            <Typography
              variant="body1"
              sx={{
                wordWrap: "break-word",
                textAlign: isPlayer ? "right" : "left",
              }}
            >
              {message}
            </Typography>
          </Paper>
        </Box>
      </div>
    </div>
  );
};

export default MessageBubble;
