import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";

export const DeleteDialog = (props) => {

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Delete Game?"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Deleting the game will kick all users and remove all player data
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose}>Cancel</Button>
        <Button
          onClick={() => {
            props.handleDeleteGame();
          }}
          autoFocus
          variant="contained"
          color="error"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const LeaveGameDialog = (props) => {
  return (
    <Dialog
      open={props.leaveGameOpen}
      onClose={props.handleLeaveGameClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Leave Game?"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Leaving This Game after it has started is perminant and you cannot
          rejoin
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleLeaveGameClose}>Cancel</Button>
        <Button onClick={props.handleLeaveGame} autoFocus>
          Leave
        </Button>
      </DialogActions>
    </Dialog>
  );
};
