import React, { useEffect, useState } from "react";
import { Button, Divider, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import gm_logo from "../../assets/GM_Logo.png";
import CircularProgress from "@mui/material/CircularProgress";
import PeopleIcon from "@mui/icons-material/People";
import PersonIcon from "@mui/icons-material/Person";
import coinsImageLowRes from "../../assets/coinsLowRes.png";
import curseGem from "../../assets/curseGem.png";
import { GameButtonCustom } from "../MUI_Components/Components";
import "./Pages.css";

// Add PendingWatermark styled component
const PendingWatermark = styled("div")({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%) rotate(-45deg)",
  fontSize: "5rem",
  color: "rgba(0, 0, 0, 0.3)", // Light and transparent
  fontWeight: "bold",
  zIndex: 10, // Ensure it's on top
  pointerEvents: "none", // So it doesn't block interactions
});

const ColorButton = styled(Button)(({ theme }) => ({
  color: "black",
  height: "40px",
  fontSize: "20px",
  width: "250px",
  marginTop: "15px",
  backgroundColor: "#D3D2CC",
  border: "3px",
  borderStyle: "outset",
  borderRadius: "0px",
  "&:hover": {
    backgroundColor: "#999894",
  },
  height: "auto",
}));

const ChallengeCard = (props) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  // useEffect(() => {
  //   console.log("props", props?.pendingCurses);

  //   console.log(props?.pendingCurses);
  //   const hasPending = props?.pendingCurses?.filter(
  //     (curse) => curse.approvedByGM === false
  //   );
  //   console.log("haspending", hasPending);
  //   setHasPendingCurses(hasPending?.length > 0);
  // }, [props]);

  function complete() {
    handleClose();
    setLoading(props.challenge.title);
    props.completeChallenge(props.challenge.challengeId);
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="ChallengeCardContainer">
      <Divider
        sx={{
          borderBottomWidth: "2px",
          backgroundColor: "black",
          marginBottom: "10px",
        }}
      />
      {props.challenge.description.includes(":-:GM") && (
        <img src={gm_logo} alt="GM LOGO" className="gmLogo" />
      )}
      {loading === props.challenge.title ? (
        <div className="LoadingOverCard">
          <CircularProgress />
        </div>
      ) : null}
      <div
        className={`ChallengeCard ${
          loading === props.challenge.title ? "loadingChallenge" : ""
        }`}
        style={{ position: "relative" }} // Ensure relative positioning for watermark
      >
        {/* Conditionally render the watermark */}
        {props?.pending && <PendingWatermark>PENDING</PendingWatermark>}
        <Typography
          variant="h5"
          sx={{
            marginBottom: "10px",
            fontWeight: "bold",
            fontFamily: "inherit",
          }}
        >
          {props.challenge.title}
        </Typography>
        <div className="rewardContainer">
          <img
            src={coinsImageLowRes}
            alt="coinsIcon"
            style={{ height: "30px" }}
          />
          <Typography variant="h5" sx={{ fontFamily: "inherit" }}>
            {props.challenge.value}
          </Typography>
          {props.challenge.gems > 0 && (
            <>
              <img
                src={curseGem}
                alt="gemsIcon"
                style={{
                  height: "30px",
                  marginLeft: "20px",
                  marginRight: "5px",
                }}
              />
              <Typography variant="h5" sx={{ fontFamily: "inherit" }}>
                {props.challenge.gems}
              </Typography>
            </>
          )}

          {props.challenge.description.includes(":-:MP") && (
            <PeopleIcon sx={{ height: "auto", marginLeft: "20px" }} />
          )}
          {props.challenge.description.includes(":-:SP") && (
            <PersonIcon sx={{ height: "auto", marginLeft: "20px" }} />
          )}
        </div>
        <Typography variant="h6" sx={{ fontFamily: "inherit" }}>
          {props.challenge.description
            .replace(":-:GM", "")
            .replace(":-:MP", "")
            .replace(":-:SP", "")}
        </Typography>
        <Divider
          sx={{
            borderBottomWidth: "2px",
            backgroundColor: "black",
            marginTop: "10px",
          }}
        />{" "}
        <div className="buttonContainer">
          <ColorButton
            variant="contained"
            color="primary"
            sx={{ borderColor: "black", fontFamily: "inherit" }}
            disabled={props.completed || props?.pendingCurses?.length > 0}
            onClick={() => {
              handleClickOpen();
            }}
          >
            {props.completed
              ? "Completed"
              : props?.pendingCurses?.length > 0
              ? "Pending Curses Approval"
              : "Complete"}
          </ColorButton>
        </div>
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Complete Challenge"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Officially Submit completion of this task! Please make sure you save
            proof of completion for later.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <GameButtonCustom onClick={complete} autoFocus variant="contained">
            Complete
          </GameButtonCustom>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ChallengeCard;
