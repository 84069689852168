import React, { useEffect, useState, useRef } from "react";
import { TitleBox } from "../../MUI_Components/Components";
import { Typography } from "@mui/material";
import { onValue, ref } from "firebase/database";
import { getDB } from "../../../firebase";
import { convertObjectToArrayWithIdBase, getLatestTimestamp } from "../../../utils/utils";
import { useAuth } from "../../../contexts/AuthContext";
import MessageBubble from "./MessageBubble";
import "./Chat.css"; // Adjust the path according to your project structure
import { useChat } from "../../../contexts/ChatContext";

const Chat = ({ user, game }) => {
  const { currentUser } = useAuth();
  const [names, setNames] = useState([]);
  const [loading, setLoading] = useState(true);
  const { updateUnreadMessages } = useChat();
  const [messages, setMessages] = useState([]);
  const { updateLastReadMessageId } = useChat();

  const messagesEndRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    console.log("mess112", getLatestTimestamp(messages))
    let lastReadMessageId = getLatestTimestamp(messages)
    localStorage.setItem("lastReadMessageId", lastReadMessageId.toString());
  }, [messages]);

  useEffect(() => {
    updateUnreadMessages(false);
    // Create an observer to scroll to bottom when messages change
    const observer = new MutationObserver(() => {
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({ behavior: "auto" });
      }
    });

    if (containerRef.current) {
      observer.observe(containerRef.current, { childList: true });
    }

    return () => {
      observer.disconnect();
    };
  }, [updateUnreadMessages]);

  useEffect(() => {
    // Scroll to bottom on initial render
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, []); // Empty dependency array for running only once on mount

  useEffect(() => {
    if (user.game === "false") {
      return;
    }

    const gameQuery = ref(getDB(), "/games/" + user.game + "/messages");
    const unsubscribe = onValue(gameQuery, (snapshot) => {
      if (snapshot.exists() && snapshot.val() !== null) {
        let messagesArray = convertObjectToArrayWithIdBase(snapshot.val());
        setMessages(messagesArray);
        const mostRecentMessage = messagesArray[messagesArray.length - 1];
        updateLastReadMessageId(mostRecentMessage.timestamp);
      }
    });

    return () => unsubscribe();
  }, [user.game, updateUnreadMessages, updateLastReadMessageId]);

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    console.log(user.game);
    var raw = JSON.stringify({
      gameId: user.game,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    console.log(game.players);

    const fetchUserNames = fetch(
      "https://getplayerusernames-nykygdtd7a-uc.a.run.app",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => JSON.parse(result));

    Promise.all([fetchUserNames])
      .then(([namesResult]) => {
        setNames(namesResult);
        console.log("names", namesResult);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  return (
    <div
      style={{
        height: `calc(100dvh - 250px)`, // Height adjusted for the navbar
        width: "100%",
        position: "relative",
        top: 0,
      }}
    >
      <TitleBox>
        <Typography variant="h3" className="gradient-text">
          Chat
        </Typography>
      </TitleBox>

      <div
        ref={containerRef}
        className="chatContainer" // Apply the CSS class
      >
        {messages?.length > 0 &&
          messages.map((message) => (
            <MessageBubble
              key={message.id} // Ensure each message has a unique key
              message={message.message}
              timestamp={message.timestamp}
              username={names[message.uid]}
              isPlayer={currentUser.uid === message.uid}
            />
          ))}
        <div ref={messagesEndRef} />
      </div>
    </div>
  );
};

export default Chat;
