import React, { useEffect, useState } from "react";
import {
  Avatar,
  Typography,
  TextField,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
} from "@mui/material";
import { getDatabase, ref, onValue } from "firebase/database";
import { styled } from "@mui/system";
import coinsImage from "../../assets/coins.png";
import gemsImage from "../../assets/curseGem.png";
import cursed from "../../assets/cursed.svg";
import ChallengeCard from "./ChallengeCardGM";
import EditIcon from "@mui/icons-material/Edit";
import "./ControlGameUserTab.css";

const getRandomInt = (min, max) =>
  Math.floor(Math.random() * (max - min + 1)) + min;

const ColorButton = styled(Button)(({ theme }) => ({
  color: "white",
  borderRadius: "15px",
  height: "40px",
  fontSize: "20px",
  marginTop: "15px",
}));

const ChallengeCardStyled = styled("div")({
  backgroundColor: "#F3F0E6",
  borderRadius: "2px",
  border: "5px solid",
  color: "black",
});

const PendingChallengeCardStyled = styled(ChallengeCardStyled)({
  opacity: "0.7",
});

const CompletedChallengeCardStyled = styled(ChallengeCardStyled)({
  opacity: "0.3",
});

const CurseCardDiv = styled("div")({
  // background: `url(${cursed})`,
  backgroundColor: "black",
  color: "white",
  backgroundSize: "cover",
  borderRadius: "8px",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
  transform: (props) =>
    `rotate(${getRandomInt(-5, 5)}deg) scale(${getRandomInt(95, 105) / 100})`,
});

const ControlGameUserTab = ({ player, game, code }) => {
  const [userData, setUserData] = useState({});
  const [playerData, setPlayerData] = useState({});
  const [currentChallenges, setCurrentChallenges] = useState([]);
  const [completedChallenges, setCompletedChallenges] = useState([]);
  const [completedChallengesApproved, setCompletedChallengesApproved] =
    useState([]);
  const [currentCurses, setCurrentCurses] = useState([]);
  const [completedCurses, setCompletedCurses] = useState([]);
  const [approvedCurses, setApprovedCurses] = useState([]);
  // State variables for adjusting coins and gems
  const [coinsDialogOpen, setCoinsDialogOpen] = useState(false);
  const [gemsDialogOpen, setGemsDialogOpen] = useState(false);
  const [coinsAdjustment, setCoinsAdjustment] = useState(0);
  const [gemsAdjustment, setGemsAdjustment] = useState(0);
  const [loadingStates, setLoadingStates] = useState({}); // To track loading state per challenge

  useEffect(() => {
    if (!player.uid) return;

    const db = getDatabase();

    // Reference to the user's data under 'users/{userId}'
    const userRef = ref(db, `users/${player.uid}`);
    // Reference to the player's data under 'games/{gameId}/players/{playerId}'
    const playerRef = ref(db, `games/${code}/players/${player.uid}`);

    // Listener for user data (name, photo, etc.)
    const userUnsubscribe = onValue(userRef, (snapshot) => {
      const data = snapshot.val() || {};
      setUserData(data);
    });

    // Listener for player data (challenges, coins, gems, curses, etc.)
    const playerUnsubscribe = onValue(playerRef, (snapshot) => {
      const data = snapshot.val() || {};
      setPlayerData(data);

      // Process challenges
      const convertChallenges = (challengesObj) =>
        challengesObj
          ? Object.entries(challengesObj)
              .filter(([, value]) => value !== null)
              .map(([key, value]) => ({
                ...value,
                challengeId: parseInt(key),
              }))
          : [];

      const current = convertChallenges(data.currentChallenges);
      const completed = convertChallenges(data.completedChallenges);

      setCurrentChallenges(current);

      setCompletedChallenges(completed.filter((item) => !item.approvedByGM));

      setCompletedChallengesApproved(
        completed.filter((item) => item.approvedByGM)
      );

      // Process curses
      const curses = data.curses?.major || [];
      const completedCurses = data.completedCurses || [];

      const currentCurses = Object.entries(curses).filter(
        ([, curse]) => !curse.completed && !curse.approvedByGM
      );
      const completedCursesList = Object.entries(completedCurses).filter(
        ([, curse]) => !curse.approvedByGM
      );
      const approvedCursesList = Object.entries(completedCurses).filter(
        ([, curse]) => curse.approvedByGM
      );

      setCurrentCurses(currentCurses);
      setCompletedCurses(completedCursesList);
      setApprovedCurses(approvedCursesList);
    });

    // Cleanup listeners on unmount
    return () => {
      userUnsubscribe();
      playerUnsubscribe();
    };
  }, [player.uid, code]);

  const approveCurse = (curseId) => {
    setLoadingState(curseId, "approve", true, true);
    const endpoint = "https://approvecurse-nykygdtd7a-uc.a.run.app";
    const data = {
      gameId: code,
      playerId: player.uid,
      curseId,
    };

    fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(() => setLoadingState(curseId, "approve", false, true))
      .catch((error) => {
        console.error("Error:", error);
        setLoadingState(curseId, "approve", false, true);
      });
  };

  const denyCurse = (curseId) => {
    setLoadingState(curseId, "deny", true, true);
    const endpoint = "https://gmdenycurse-nykygdtd7a-uc.a.run.app";
    const data = {
      gameId: code,
      playerId: player.uid,
      curseId,
    };

    fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(() => setLoadingState(curseId, "deny", false, true))
      .catch((error) => {
        console.error("Error:", error);
        setLoadingState(curseId, "deny", false, true);
      });
  };

  const setLoadingState = (id, type, state, isCurse = false) => {
    setLoadingStates((prev) => ({
      ...prev,
      [isCurse ? `curse-${id}` : id]: {
        ...prev[isCurse ? `curse-${id}` : id],
        [type]: state,
      },
    }));
  };

  const approveChallenge = (challengeId) => {
    setLoadingState(challengeId, "approve", true);
    const endpoint = "https://approvechallenge-nykygdtd7a-uc.a.run.app";
    const data = {
      gameId: code,
      playerId: player.uid,
      challengeId,
    };

    fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(() => setLoadingState(challengeId, "approve", false))
      .catch((error) => {
        console.error("Error:", error);
        setLoadingState(challengeId, "approve", false);
      });
  };
  const denyChallenge = (challengeId) => {
    setLoadingState(challengeId, "deny", true);
    const endpoint = "https://gmdenychallenge-nykygdtd7a-uc.a.run.app";
    const data = {
      gameId: code,
      playerId: player.uid,
      challengeId,
    };

    fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(() => setLoadingState(challengeId, "deny", false))
      .catch((error) => {
        console.error("Error:", error);
        setLoadingState(challengeId, "deny", false);
      });
  };

  // Handlers for coins adjustment
  const handleEditCoins = () => {
    setCoinsDialogOpen(true);
    setCoinsAdjustment(0);
  };

  const handleCoinsDialogClose = () => {
    setCoinsDialogOpen(false);
  };

  const handleCoinsAdjustmentChange = (event) => {
    setCoinsAdjustment(event.target.value);
  };

  const handleCoinsAdjustmentSubmit = () => {
    const endpoint = `https://modifyplayercoins-nykygdtd7a-uc.a.run.app?playerUID=${player.uid}&gameID=${code}&value=${coinsAdjustment}`;

    fetch(endpoint)
      .then(() => {
        // Handle success
        setCoinsDialogOpen(false);
      })
      .catch((error) => {
        // Handle error
        console.error("Error modifying coins:", error);
      });
  };

  // Handlers for gems adjustment
  const handleEditGems = () => {
    setGemsDialogOpen(true);
    setGemsAdjustment(0);
  };

  const handleGemsDialogClose = () => {
    setGemsDialogOpen(false);
  };

  const handleGemsAdjustmentChange = (event) => {
    setGemsAdjustment(event.target.value);
  };

  const handleGemsAdjustmentSubmit = () => {
    const endpoint = `https://modifyplayergems-nykygdtd7a-uc.a.run.app?playerUID=${player.uid}&gameID=${code}&value=${gemsAdjustment}`;

    fetch(endpoint)
      .then(() => {
        // Handle success
        setGemsDialogOpen(false);
      })
      .catch((error) => {
        // Handle error
        console.error("Error modifying gems:", error);
      });
  };

  return (
    <div style={{ padding: "20px" }}>
      <div className="titleBarUser">
        <div className="playerAvatar">
          <Avatar
            src={userData.image || ""}
            alt={userData.username || ""}
            sx={{ width: 100, height: 100, alignItems: "center" }}
          >
            {userData.username?.charAt(0)}
          </Avatar>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography variant="h3">{playerData.username}</Typography>
          <div className="teamInfoSub">
            <Typography variant="h5" sx={{ marginRight: "20px" }}>
              Game: {code}
            </Typography>
            <div className="teamInfoCoins">
              <img src={coinsImage} alt="coins" className="coins" />
              <Typography variant="h5" sx={{ marginRight: "5px" }}>
                {playerData.coins || 0}
              </Typography>
              <IconButton
                onClick={handleEditCoins}
                size="small"
                sx={{ padding: "5px", color: "white" }}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </div>
            <div className="teamInfoCoins" style={{ marginLeft: "10px" }}>
              <img src={gemsImage} alt="gems" className="coins" />
              <Typography variant="h5" sx={{ marginRight: "5px" }}>
                {playerData.gems || 0}
              </Typography>
              <IconButton
                onClick={handleEditGems}
                size="small"
                sx={{ padding: "5px", color: "white" }}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </div>
          </div>
        </div>
      </div>
      {/* Dialog for adjusting coins */}
      <Dialog open={coinsDialogOpen} onClose={handleCoinsDialogClose}>
        <DialogTitle>Adjust Coins</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="coinsAdjustment"
            label="Coins Adjustment"
            type="number"
            fullWidth
            value={coinsAdjustment}
            onChange={handleCoinsAdjustmentChange}
          />
          <Typography variant="body2">
            Enter a positive or negative number to adjust the player's coins.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCoinsDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCoinsAdjustmentSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for adjusting gems */}
      <Dialog open={gemsDialogOpen} onClose={handleGemsDialogClose}>
        <DialogTitle>Adjust Gems</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="gemsAdjustment"
            label="Gems Adjustment"
            type="number"
            fullWidth
            value={gemsAdjustment}
            onChange={handleGemsAdjustmentChange}
          />
          <Typography variant="body2">
            Enter a positive or negative number to adjust the player's gems.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleGemsDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleGemsAdjustmentSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
          maxHeight: "400px",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        {/* Current Curses */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "30%",
            alignItems: "center",
          }}
        >
          <Typography variant="h5" sx={{ textAlign: "center", color: "white" }}>
            Current Curses
          </Typography>
          {currentCurses.length === 0 ? (
            <Typography
              variant="subtitle1"
              sx={{ textAlign: "center", color: "white", marginTop: "30px" }}
            >
              No Current Curses
            </Typography>
          ) : (
            currentCurses.map(([curseId, curse], index) => (
              <CurseCardDiv
                key={index}
                sx={{
                  marginBottom: "10px",
                  padding: "10px",
                  width: "calc(100% - 10px)",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ marginBottom: "5px", fontWeight: "bold" }}
                >
                  {curse.curseName}
                </Typography>
                <Typography variant="subtitle1" sx={{ fontStyle: "italic" }}>
                  Cursed by {curse.cursedBy}
                </Typography>
                <Typography variant="subtitle1">
                  {curse.curseDescription}
                </Typography>
              </CurseCardDiv>
            ))
          )}
        </div>

        {/* Completed Curses (Pending Approval) */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "30%",
            alignItems: "center",
          }}
        >
          <Typography variant="h5" sx={{ textAlign: "center", color: "white" }}>
            Completed Curses
          </Typography>
          {completedCurses.length === 0 ? (
            <Typography
              variant="subtitle1"
              sx={{ textAlign: "center", color: "white", marginTop: "30px" }}
            >
              No Completed Curses
            </Typography>
          ) : (
            completedCurses.map(([curseId, curse], index) => (
              <CurseCardDiv
                key={index}
                sx={{
                  marginBottom: "10px",
                  padding: "10px",
                  width: "calc(100% - 10px)",
                  opacity: "0.8",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ marginBottom: "5px", fontWeight: "bold" }}
                >
                  {curse.curseName}
                </Typography>
                <Typography variant="subtitle1" sx={{ fontStyle: "italic" }}>
                  Cursed by {curse.cursedBy}
                </Typography>
                <Typography variant="subtitle1">
                  {curse.curseDescription}
                </Typography>
                <Typography variant="subtitle1">
                  {curse.vetoed ? "Vetoed" : "Completed, pending approval"}
                </Typography>
                <div>
                  <ColorButton
                    onClick={() => approveCurse(curseId)}
                    variant="contained"
                    fullWidth
                    color="success"
                    sx={{ marginRight: "10px" }}
                    disabled={loadingStates[`curse-${curseId}`]?.approve}
                  >
                    {loadingStates[`curse-${curseId}`]?.approve
                      ? "Approving..."
                      : "Approve"}
                  </ColorButton>
                  <ColorButton
                    onClick={() => denyCurse(curseId)}
                    variant="contained"
                    fullWidth
                    color="error"
                    disabled={loadingStates[`curse-${curseId}`]?.deny}
                  >
                    {loadingStates[`curse-${curseId}`]?.deny
                      ? "Denying..."
                      : "Deny"}
                  </ColorButton>
                </div>
              </CurseCardDiv>
            ))
          )}
        </div>

        {/* Approved Curses */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "30%",
            alignItems: "center",
          }}
        >
          <Typography variant="h5" sx={{ textAlign: "center", color: "white" }}>
            Approved Curses
          </Typography>
          {approvedCurses.length === 0 ? (
            <Typography
              variant="subtitle1"
              sx={{ textAlign: "center", color: "white", marginTop: "30px" }}
            >
              No Approved Curses
            </Typography>
          ) : (
            approvedCurses.map(([curseId, curse], index) => (
              <CurseCardDiv
                key={index}
                sx={{
                  marginBottom: "10px",
                  padding: "10px",
                  width: "calc(100% - 10px)",
                  opacity: "0.4",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ marginBottom: "5px", fontWeight: "bold" }}
                >
                  {curse.curseName}
                </Typography>
                <Typography variant="subtitle1" sx={{ fontStyle: "italic" }}>
                  Cursed by {curse.cursedBy}
                </Typography>
                <Typography variant="subtitle1">
                  {curse.curseDescription}
                </Typography>
                <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                  {curse.vetoed ? "Vetoed" : "Approved"}
                </Typography>
              </CurseCardDiv>
            ))
          )}
        </div>
      </div>
      <Divider sx={{ bgcolor: "white", marginY: "30px" }} />

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        {/* Current Challenges */}
        <div style={{ display: "flex", flexDirection: "column", width: "30%" }}>
          <Typography variant="h5" sx={{ textAlign: "center", color: "white" }}>
            Current Cards
          </Typography>
          {currentChallenges.map((challenge, index) => (
            <ChallengeCardStyled
              key={index}
              sx={{
                marginBottom: "10px",
                padding: "10px",
                width: "calc(100% - 10px)",
              }}
            >
              <ChallengeCard
                challenge={challenge}
                approveChallenge={approveChallenge}
                denyChallenge={denyChallenge}
                loadingStates={loadingStates[challenge.challengeId] || {}}
                approved={false}
                completed={false}
                checkTimings={false}
              />
            </ChallengeCardStyled>
          ))}
        </div>

        {/* Completed Challenges */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "30%",
            alignItems: "center",
          }}
        >
          <Typography variant="h5" sx={{ textAlign: "center", color: "white" }}>
            Completed Cards
          </Typography>
          {completedChallenges.length === 0 ? (
            <Typography
              variant="subtitle1"
              sx={{ textAlign: "center", color: "white", marginTop: "30px" }}
            >
              No Completed Challenges
            </Typography>
          ) : (
            completedChallenges.map((challenge, index) => (
              <PendingChallengeCardStyled
                key={index}
                sx={{
                  marginBottom: "10px",
                  padding: "10px",
                  width: "calc(100% - 10px)",
                }}
              >
                <ChallengeCard
                  challenge={challenge}
                  approveChallenge={approveChallenge}
                  denyChallenge={denyChallenge}
                  loadingStates={loadingStates[challenge.challengeId] || {}}
                  completed={true}
                  loading={false}
                  approved={true}
                  checkTimings={false}
                />
              </PendingChallengeCardStyled>
            ))
          )}
        </div>

        {/* Approved Challenges */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "30%",
            alignItems: "center",
          }}
        >
          <Typography variant="h5" sx={{ textAlign: "center", color: "white" }}>
            Approved Cards
          </Typography>
          {completedChallengesApproved.length === 0 ? (
            <Typography
              variant="subtitle1"
              sx={{ textAlign: "center", color: "white", marginTop: "30px" }}
            >
              No Approved Challenges
            </Typography>
          ) : (
            completedChallengesApproved.map((challenge, index) => (
              <CompletedChallengeCardStyled
                key={index}
                sx={{
                  marginBottom: "10px",
                  padding: "10px",
                  width: "calc(100% - 10px)",
                }}
              >
                <ChallengeCard
                  challenge={challenge}
                  completed={true}
                  approved={false}
                  loading={false}
                  checkTimings={false}
                />
              </CompletedChallengeCardStyled>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default ControlGameUserTab;
