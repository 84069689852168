import React, { useState, useEffect } from "react";
import {
  BlueIconButton,
  RoundedTextFieldVisible,
} from "../../MUI_Components/Components";
import SendIcon from "@mui/icons-material/Send";
import { useAuth } from "../../../contexts/AuthContext";

const ChatTextField = () => {
  const { currentUser } = useAuth();
  const [message, setMessage] = useState("");
  const [messageQueue, setMessageQueue] = useState([]);
  const [isSending, setIsSending] = useState(false);

  function sendMessage() {
    if (message.length === 0) {
      return;
    }
    // Add message to queue instead of sending immediately
    setMessageQueue((prevQueue) => [...prevQueue, message]);
    setMessage("");
  }

  useEffect(() => {
    // If there's something in the queue and nothing is currently being sent
    if (messageQueue.length > 0 && !isSending) {
      sendNextMessage();
    }
  }, [messageQueue, isSending]); // useEffect will run when `messageQueue` or `isSending` changes

  function sendNextMessage() {
    if (messageQueue.length === 0) {
      return;
    }
    setIsSending(true);
    const nextMessage = messageQueue[0];
    console.log(nextMessage);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      uid: currentUser.uid,
      message: nextMessage,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://sendmessage-nykygdtd7a-uc.a.run.app", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        console.log(result);
        // Remove the sent message from the queue
        setMessageQueue((prevQueue) => prevQueue.slice(1));
        setIsSending(false);
      })
      .catch((error) => {
        console.log("error", error);
        setIsSending(false);
      });
  }
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        backgroundColor: "#181716",
        padding: "4px",
      }}
    >
      <RoundedTextFieldVisible
        placeholder="Message"
        sx={{ color: "white" }}
        fullWidth
        value={message}
        onChange={(e) => {
          setMessage(e.target.value);
        }}
      />
      <BlueIconButton
        sx={{ width: "70px" }}
        onClick={sendMessage}
        disabled={isSending}
      >
        <SendIcon />
      </BlueIconButton>
    </div>
  );
};

export default ChatTextField;
